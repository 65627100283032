<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== APPIE HEADER PART START ======-->

    <header-home-one @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HEADER PART ENDS ======-->
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <div class="appie-about-top-title-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="appie-about-top-title">
                  <h2 class="title">About Us</h2>
                  <p>
                    At Travlog, we're a community of avid travelers and
                    explorers who are passionate about documenting our journeys
                    and sharing valuable travel recommendations.
                    <br />
                    <br />
                    Our platform empowers you to create your personal travel
                    journal, effortlessly capture your experiences, and discover
                    hidden gems from fellow adventurers around the globe.
                    <br />
                    <br />
                    We're here to guide you through the entire process, ensuring
                    your travel memories are preserved and easily shared with
                    family and friends.
                    <br />
                    <br />
                    By using our platform, you can contribute to bringing
                    travelers closer together, offering comfort and inspiration
                    to those embarking on new adventures.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--====== APPIE HEADER PART ENDS ======-->

        <!--====== APPIE HEADER PART ENDS ======-->

        <section class="appie-about-page-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="appie-about-page-content">
                  <h3 class="title">Origin</h3>
                  <p>
                    The idea behind Travlog was born from a simple realization -
                    the joy of traveling and experiencing new places is an
                    invaluable source of happiness and personal growth. However,
                    after a few years, we found ourselves struggling to recall
                    the specifics of those remarkable adventures. It became
                    clear that the memories we had created were fading, and we
                    wanted a way to preserve and cherish them.
                    <br />
                    <br />
                    We believed that these travel memories were not just
                    personal treasures but also a source of inspiration for
                    others. So, we embarked on a journey of our own, one that
                    involved building an app to address this very problem. Our
                    mission was to create a platform where travelers could
                    effortlessly document their adventures, curate personal
                    journals, and share their experiences with friends and the
                    global travel community.
                    <br />
                    <br />
                    Today, Travlog stands as a testament to our belief in the
                    value of travel memories and our commitment to helping
                    people hold onto those moments that have shaped them,
                    inspired others, and brought us all closer together.
                  </p>
                  <a href="#bottom">
                    View our Team <i class="fal fa-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- <div class="col-lg-5 appie-about-top-title-area">
        <img
          src="../../assets/images/skyscraper.jpg"
          alt=""
          style="box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1)"
        />
      </div> -->
      <div class="col-lg-1"></div>
    </div>

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->

    <!-- <service-aboutus /> -->

    <!--====== APPIE SERRVICE 2 PART ENDS ======-->

    <!--====== APPIE TEAM PART START ======-->

    <team-aboutus id="bottom" />

    <!--====== APPIE TEAM PART ENDS ======-->

    <!--====== APPIE SPONSER PART START ======-->

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE SPONSER PART ENDS ======-->
    <!-- <get-started /> -->

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one class="appie-footer-area appie-footer-about-area" />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import HeaderHomeOne from "../HomeOne/HeaderHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
// import GetStarted from "./GetStarted.vue";
// import ServiceAboutus from "./ServiceAboutus.vue";
import TeamAboutus from "./TeamAboutus.vue";
export default {
  metaInfo: {
    title: "About Us",
  },
  components: {
    SidebarHomeOne,
    HeaderHomeOne,
    // ServiceAboutus,
    TeamAboutus,
    // GetStarted,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,

      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About Us",
          path: "/about-us",
        },
        {
          name: "Blog",
          path: "/blog",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "FAQs",
          path: "/faqs",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>
