<template>
  <div class="appie-hero-area">
    <img src="../../assets/images/logoWhitePink.png" />
    <img src="../../assets/images/shape/worldwide1.png" alt="" />
  </div>
</template>
<script>
export default {
  name: "Post",
};
</script>
