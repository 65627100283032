var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"blog-sidebar"},[_c('aside',{staticClass:"widget widget-search"},[_c('div',{staticClass:"search-form",on:{"keypress":(event) => {
            if (event.key === 'Enter') {
              _vm.$emit('search', _vm.query);
            }
          }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],attrs:{"type":"search","name":"s","placeholder":"Search..."},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}}),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('search', _vm.query)}}},[_c('i',{staticClass:"fal fa-search"})])])]),_c('aside',{staticClass:"widget widget-categories"},[_c('h3',{staticClass:"widget-title"},[_vm._v("Categories")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":() => (_vm.author = !_vm.author)}},[_vm._v("Authors")]),_c('ul',[(_vm.author)?_c('div',_vm._l((_vm.authors),function(author,index){return _c('li',{key:index,attrs:{"id":"list-item"}},[_c('a',{attrs:{"href":"#"},on:{"click":() => {
                      _vm.changeColor(index);
                      _vm.$emit('filterAuthor', author);
                    }}},[_vm._v(_vm._s(author))])])}),0):_vm._e()])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":() => (_vm.continent = !_vm.continent)}},[_vm._v("Continents")]),_c('ul',[(_vm.continent)?_c('div',_vm._l((_vm.continents),function(continent,index){return _c('li',{key:index,attrs:{"id":"list-item"}},[_c('a',{attrs:{"href":"#"},on:{"click":() => {
                      _vm.changeColor(index);
                      _vm.$emit('filterContinent', continent);
                    }}},[_vm._v(_vm._s(continent))])])}),0):_vm._e()])])])]),_c('aside',{staticClass:"widget"},[_c('h3',{staticClass:"widget-title"},[_vm._v("Popular Tags")]),_c('div',{staticClass:"tags"},_vm._l((_vm.tags),function(tag,index){return _c('a',{key:index,attrs:{"href":"#","id":index},on:{"click":() => {
              _vm.changeColor(index);
              _vm.$emit('filterTag', tag);
            }}},[_vm._v(_vm._s(tag))])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }