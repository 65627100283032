<template>
  <Support />
</template>

<script>
import Support from "../components/Support/index";
export default {
  components: { Support },
};
</script>
