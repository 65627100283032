<template>
    <section class="appie-hero-area">
      <div class="container">
        <div style="margin-bottom: 30px">
          <h2>Privacy Rights Summary Notice for Travlog</h2>
          <p><em>Effective Date: March 6th, 2024</em></p>
        </div>
        <p>This page supplements our <a href="https://www.travlogapp.com/terms-and-conditions">Terms of Service</a> and <a href="https://www.travlogapp.com/privacy">Privacy Policy</a>. If you are a California, Connecticut, Colorado, Utah, or Virginia consumer, this Privacy Rights Summary Notice (“<u>Notice</u>”) will apply to you. This Notice explains the categories of personal information/data (“<u>personal information</u>”) that we collect and how we use them in accordance with the California Consumer Privacy Act (“<u>CCPA</u>”) (as amended by the California Privacy Rights Act (“<u>CPRA</u>”), the Colorado Privacy Act (“<u>CPA</u>”), the Connecticut Data Privacy Act ("<u>CTDPA</u>"), the Utah Consumer Privacy Act ("<u>UCPA</u>"), and the Virginia Consumer Data Protection Act (“<u>VCDPA</u>”) (collectively, the “<u>Privacy Laws</u>”).</p>
        <p>This also delivers information on certain opt-out rights concerning disclosures, “sales,” or “sharing” of personal information to third parties. Finally, this describes how we use “Sensitive Personal Information” and our retention schedule for each category of personal information.</p>
        <br>
        <ol>
          <li><h5>Personal Information Collection</h5></li>
        </ol>
        <p>We may collect (and may have collected during the 12-month period prior to the effective date of this Policy) the following categories of personal information about you:</p>
        <ul>
            <li>identifiers such as your name, alias, address, phone numbers and IP address;</li>
            <li>personal information, such as a credit card number or other payment information;</li>
            <li>information that may reveal age, gender, race, sexual orientation, or other protected classifications;</li>
            <li>commercial information;</li>
            <li>internet or other electronic network activity information, including content interaction information, such as content downloads, streams, and playback details, and the content of email and text messages; </li>
            <li>geolocation data, such as the location of your device or computer, which may in some cases constitute precise geolocation information;</li>
            <li>education information; </li>
            <li>professional information; and/or</li>
            <li>inference data.</li>
        </ul>
        <p>Our Service is designed for a general audience and are not directed to children. We do not knowingly collect or solicit personal information from children under the age of thirteen (13) (or other relevant ages, which may apply by virtue of applicable law).</p>
        <br>
        <ol>
          <li><h5>Personal Information Uses</h5></li>
        </ol>
        <p>During the last twelve (12) months, we may have used the personal information we collect for the following business or commercial purposes:</p>
        <ul>
            <li>Facilitating accounting, auditing, and reporting; </li>
            <li>Delivering analytics (machine generated through computing/mobile devices for performance, monitoring, personalization, and order processing);</li>
            <li>Delivering advertising through technology (including, for example, to facilitate personalized content, remarketing, online display ads, and interest-based ads);</li>
            <li>Delivering advertising through direct or mass media (including, for example, via email, SMS, telephone, post, and broadcasts);</li>
            <li>Facilitating affiliate marketing;</li>
            <li>Administering claims management, handling, and insurance;</li>
            <li>Responding to incidents;</li>
            <li>Delivering customer service;</li>
            <li>Pursuing legal matters;</li>
            <li>Delivering website, mobile-app, and e-commerce services;</li>
            <li>Facilitating information security;</li>
            <li>Facilitating fraud monitoring and prevention;</li>
            <li>Delivering logistics (including, order management, shipping, and fulfillment);</li>
            <li>Develop customer information for personalization;</li>
            <li>Processing, fulfilling, and shipping orders;</li>
            <li>Deliver shopping and customer engagement;</li>
            <li>Delivering and improving social media engagement;</li>
            <li>Conducting surveys;</li>
            <li>Administering technology and ensuring technology integrity (including, for example, by maintaining and improving networks; and identifying and fixing problems); </li>
            <li>Processing transactions and payments;</li>
            <li>Developing, reviewing, and testing products and services; and/or</li>
            <li>Performing other miscellaneous services.</li>
        </ul>
        <br>
        <ol>
          <li><h5>Opting Out of “Sale” or “Share” of Personal Information </h5></li>
        </ol>
        <p>You also have the right to opt out of the “selling” or “sharing” of your personal information. We do not have actual knowledge that we sell or share personal information of consumers under the age of 16.</p>
        <p>To specify your preferences, California and Colorado consumers may visit our Do Not Sell My Personal Information page. We will not deny, charge different prices for, or provide a different level or quality of goods or services, if you choose to exercise any of your privacy rights.</p>
        <br>
        <ol>
          <li><h5>Opting Out of Information Disclosures to Unaffiliated Third Parties</h5></li>
        </ol>
        <p>In addition to the rights mentioned above, we provide you with a cost-free means to opt-out of our sharing your information with third parties with whom we do not share the same brand name, if that third party will use it for its own direct marketing purposes.</p>
        <p>If you would like to exercise this right under California law, please complete the form on our Do Not Sell My Personal Information page.</p>
        <br>
        <ol>
          <li><h5>Sensitive Personal Information</h5></li>
        </ol>
        <p>We will only process Sensitive Personal Information where it is necessary for the purposes of carrying out our legal obligations or exercising specific rights as permitted by law. </p>
        <br>
        <ol>
          <li><h5>Retention Policy</h5></li>
        </ol>
        <p>We seek to ensure that we retain only information necessary to effectively service our customers; provide relevant product assortments and advertisements; assist you with customer service-related matters; and comply with our legal obligations. The need to retain personal information varies widely with the type of information and the purpose for which it was collected. We strive to ensure that personal information is only retained for the period to fulfill the purpose for which it was collected.</p>
        <br>
        <ol>
          <li><h5>Contact Us</h5></li>
        </ol>
        <p>If you have general questions about this Notice, please email us at <a href="mailto:support@travlogapp.com">support@travlogapp.com</a> or write to us at 6908 Stones Throw Cir N, Apt 10204, Saint Petersburg, FL 33710. </p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "PrivacyRights",
  };
  </script>
  