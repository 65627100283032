<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"
                ><img src="@/assets/images/logoWhite2.png" height="58px" alt=""
              /></a>
            </div>
            <p>Travlog has everything you need to travel the world</p>
            <div class="social mt-30">
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/thetravlogapp?igsh=cTI3Yjl2a3ljODZm&utm_source=qr"
                    target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a href="https://twitter.com/TheTravlogApp" target="_blank"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https:/www.youtube.com/@TheTravlogApp"
                    target="_blank"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/travlog/"
                    target="_blank"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Company</h4>
            <ul>
              <li><router-link to="/about-us">About Us</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-1 col-md-6"></div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Support</h4>
            <ul>
              <li><router-link to="/support">Legal</router-link></li>
              <li><router-link to="/faq">FAQ'S</router-link></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="footer-copyright d-flex align-items-center justify-content-between pt-35"
          >
            <div class="copyright-text">
              <p>Copyright © 2023 Travlog. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {
  mounted() {
    var emailElements = document.querySelectorAll(".clickable-email");

    emailElements.forEach(function (element) {
      element.addEventListener("click", function () {
        event.preventDefault(); // Prevent the default behavior of the anchor tag
        var emailAddress = this.innerText.trim();
        window.location.href = "mailto:" + emailAddress;
      });
    });
  },
};
</script>

<style>
.clickable-email {
  cursor: pointer;
  text-decoration: none; /* Remove underline by default */
}

.clickable-email:hover {
  cursor: pointer;
}
</style>
