<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-news @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!-- Blog Start -->
    <section class="blogpage-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <single-blog />
          </div>
          <!-- <div class="col-lg-4 col-md-5">
            <blog-sidebar />
          </div> -->
        </div>
      </div>
    </section>
    <!-- Blog End -->

    <!--====== APPIE FOOTER PART START ======-->
    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import SingleBlog from "../Blogs/SingleBlog.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import HeaderNews from "../News/HeaderNews.vue";
export default {
  components: {
    SidebarHomeOne,
    HeaderNews,
    FooterHomeOne,
    SingleBlog,
  },
  data() {
    return {
      sidebar: false,
      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About Us",
          path: "/about-us",
        },
        {
          name: "Blog",
          path: "/blog",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "FAQs",
          path: "/faqs",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>
