<template>
  <faq />
</template>

<script>
import Faq from "../components/Faq/index";
export default {
  components: {
    Faq,
  },
};
</script>

<style></style>
