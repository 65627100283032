<template>
  <section class="appie-hero-area">
    <div class="container">
      <h1>Legal Documents</h1>
      <ul class="links">
        <li>
          <router-link
            :to="{
              name: 'Privacy',
            }"
            >Privacy Policy</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'TermsAndConditions',
            }"
            >Terms and Conditions</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'AcceptableUse',
            }"
            >Acceptable Use Policy</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'PrivacyRights',
            }"
            >Privacy Rights Policy</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Cookies',
            }"
            >Cookies Policy</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Accessibility',
            }"
            >Accessibility Policy</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'TargetedAds',
            }"
            >Targeted Ads Policy</router-link
          >
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "Support",
};
</script>
