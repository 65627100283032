<template>
  <div>
    <div v-if="showloading">
      <loader-appie />
    </div>
    <div v-else>
      <div v-html="this.blogPosts.body" class="test"></div>
      <blog-home-one
        :title="'Related Blogs'"
        :blogs="this.similarBlogs"
        @click="reload"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoaderAppie from "../LoaderAppie.vue";
import BlogHomeOne from "../HomeOne/BlogHomeOne.vue";

export default {
  data() {
    return {
      blogPosts: [],
      showloading: true,
      similarBlogs: [],
      title: "",
    };
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  components: {
    LoaderAppie,
    BlogHomeOne,
  },
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    preLoading() {
      setTimeout(() => {
        this.showloading = false;
      }, 1000);
    },
    getBlog(blogID = this.$route.params.id) {
      const url = "https://api.web.travlogapp.com/singleblog";

      axios.defaults.baseURL = "/";
      return axios
        .get(url, { params: { BlogID: blogID } })
        .then((res) => {
          this.blogPosts = res.data;
          this.similarBlogs = res.data["similar_blogs"];
          this.title = res.data["title"];
        })
        .catch((err) => console.log(err));
    },
    async reload(blogID) {
      this.$router.replace({ name: "SingleBlog", params: { id: blogID } });
      this.showloading = true;
      await this.getBlog(blogID);
      this.preLoading();
    },
  },
  async created() {
    if (!this.rtl) {
      await this.getBlog();
      this.preLoading();
    } else {
      this.showloading = false;
    }
  },
};
</script>

<style>
.test p {
  margin-top: 1%;
  margin-bottom: 1%;
}

.test h1 {
  margin-top: 10%;
  margin-bottom: 5%;
}
.test img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
}
</style>
