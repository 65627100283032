<template>
  <div>
    <div class="blog-sidebar">
      <aside class="widget widget-search">
        <div
          class="search-form"
          v-on:keypress="
            (event) => {
              if (event.key === 'Enter') {
                $emit('search', query);
              }
            }
          "
        >
          <input
            type="search"
            name="s"
            placeholder="Search..."
            v-model="query"
          />
          <button type="button" v-on:click="$emit('search', query)">
            <i class="fal fa-search"></i>
          </button>
        </div>
      </aside>
      <aside class="widget widget-categories">
        <h3 class="widget-title">Categories</h3>
        <ul>
          <li>
            <a href="#" v-on:click="() => (author = !author)">Authors</a>
            <ul>
              <div v-if="author">
                <li
                  id="list-item"
                  v-for="(author, index) in authors"
                  :key="index"
                >
                  <a
                    href="#"
                    v-on:click="
                      () => {
                        changeColor(index);
                        $emit('filterAuthor', author);
                      }
                    "
                    >{{ author }}</a
                  >
                </li>
              </div>
            </ul>
          </li>
          <li>
            <a href="#" v-on:click="() => (continent = !continent)"
              >Continents</a
            >
            <ul>
              <div v-if="continent">
                <li
                  id="list-item"
                  v-for="(continent, index) in continents"
                  :key="index"
                >
                  <a
                    href="#"
                    v-on:click="
                      () => {
                        changeColor(index);
                        $emit('filterContinent', continent);
                      }
                    "
                    >{{ continent }}</a
                  >
                </li>
              </div>
            </ul>
          </li>
        </ul>
      </aside>

      <aside class="widget">
        <h3 class="widget-title">Popular Tags</h3>
        <div class="tags">
          <a
            href="#"
            v-for="(tag, index) in tags"
            :key="index"
            :id="index"
            v-on:click="
              () => {
                changeColor(index);
                $emit('filterTag', tag);
              }
            "
            >{{ tag }}</a
          >
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import ref from "vue";

// let query = ref('')

export default {
  data() {
    return {
      query: "",
      author: false,
      continent: false,
    };
  },
  props: {
    authors: null,
    continents: null,
    tags: null,
  },
  methods: {
    changeColor(index) {
      let style = document.getElementById(index).style;
      if (style.background == "rgb(248, 123, 114)") {
        style.background = "#f8f8f8";
        style.color = "#797781";
      } else {
        style.background = "#f87b72";
        style.color = "#fff";
      }
      return style;
    },
  },
};
</script>

<style></style>
