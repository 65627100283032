<template>
    <AcceptableUse />
  </template>
  
  <script>
  import AcceptableUse from "../components/AcceptableUse/index";
  export default {
    components: { AcceptableUse },
  };
  </script>
  