<template>
  <!--====== APPIE HERO PART START ======-->
  <section class="appie-hero-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="appie-hero-content">
            <span>{{ welcome_message }}</span>
            <h1 class="Travlog: The Travel Jorunal">{{ slogan }}</h1>
            <p>{{ description }}</p>
            <ul>
              <li>
                <a
                  class="item-1" href="https://apps.apple.com/in/app/apple-store/id375380948"
                  @click="openApp()"
                  ><i :class="first_button_icon"></i
                  ><span>{{ first_button_content }}</span></a
                >
              </li>
              <li>
                <a class="item-2" href="https://play.google.com/store/apps/details?id=com.travlogapp.travlog"
                @click="openApp()"
                  ><i :class="second_button_icon"></i
                  ><span>{{ second_button_content }}</span></a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="appie-hero-thumb">
            <div
              class="thumb wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <img src="@/assets/images/hero-home2-2.png" alt="" />
            </div>
            <!-- <div
              class="thumb-2 wow animated fadeInRight"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <img src="@/assets/images/hero-thumb-2.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="hero-shape-1">
      <img src="@/assets/images/shape/worldwide.png" width="50px" alt="" />
    </div>
    <div class="hero-shape-2">
      <img src="@/assets/images/shape/worldwide1.png" width="100px" alt="" />
    </div>
    <div class="hero-shape-3">
      <img src="@/assets/images/shape/worldwide1.png" width="200px" alt="" />
    </div> -->
  </section>
  <!--====== APPIE HERO PART end ======-->
</template>

<script>
export default {
  props: [
    "welcome_message",
    "slogan",
    "description",
    "thumb_one",
    "thumb_two",
    "first_button_content",
    "first_button_icon",
    "second_button_content",
    "second_button_icon",
  ],
  methods: {
    openApp(customUrlScheme = "travlog://www.travlogapp.com") {
      const appStoreUrl =
        "https://apps.apple.com/in/app/apple-store/id375380948";
      const playStoreUrl =
        "https://play.google.com/store/apps/details?id=com.travlogapp.travlog";

      const startTime = Date.now();
      window.location.href = customUrlScheme;

      setTimeout(() => {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < 1500) {
          if (navigator.userAgent.match(/(iPhone|iPod|iPad|Mac)/i)) {
            window.location.href = appStoreUrl;
          } else {
            window.location.href = playStoreUrl;
          }
        }
      }, 1000);
    },
  },
};

// document
//   .getElementById("openPostButton")
//   .addEventListener("click", function () {
//     const customUrlSchemePost =
//       "travlog://www.travlogapp.com/postID/7d2d29f1-3588-4349-8fb3-63c55f7a942f";
//     openApp(customUrlSchemePost);
//   });

// document
//   .getElementById("openUserButton")
//   .addEventListener("click", function () {
//     const customUrlSchemeUser =
//       "travlog://www.travlogapp.com/userID/d42854a8-50f1-701a-3e76-f36775809d62";
//     openApp(customUrlSchemeUser);
//   });

// function openApp(customUrlScheme) {
//   const appStoreUrl = "https://apps.apple.com/in/app/apple-store/id375380948";
//   const playStoreUrl =
//     "https://play.google.com/store/apps/details?id=com.twirsapps.weatherforecast";

//   const startTime = Date.now();
//   window.location.href = customUrlScheme;

//   setTimeout(() => {
//     const elapsedTime = Date.now() - startTime;
//     if (elapsedTime < 1500) {
//       if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
//         window.location.href = appStoreUrl;
//       } else {
//         window.location.href = playStoreUrl;
//       }
//     }
//   }, 1000);
// }
</script>

<style></style>
