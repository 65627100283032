<template>
  <div>
    <header-home-one :menu-items="navs" />
    <TermsAndConditions />
    <footer-home-one />
  </div>
</template>

<script>
import HeaderHomeOne from "../HomeOne/HeaderHomeOne.vue";
import TermsAndConditions from "./TermsAndConditions.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";

export default {
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  components: { HeaderHomeOne, TermsAndConditions, FooterHomeOne },
  data() {
    return {
      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About Us",
          path: "/about-us",
        },
        {
          name: "Blog",
          path: "/blog",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "FAQs",
          path: "/faqs",
        },
      ],
    };
  },
};
</script>
