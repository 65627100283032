<template>
  <TermsAndConditions />
</template>

<script>
import TermsAndConditions from "../components/TermsAndConditions/index";
export default {
  components: { TermsAndConditions },
};
</script>
