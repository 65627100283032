<template>
    <div style="height: 100%">
      <header-home-one :menu-items="navs" />
      <PrivacyRights />
      <footer-home-one />
    </div>
  </template>
  
  <script>
  import HeaderHomeOne from "../HomeOne/HeaderHomeOne.vue";
  import PrivacyRights from "./PrivacyRights.vue";
  import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
  
  export default {
    props: {
      rtl: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      HeaderHomeOne,
      PrivacyRights,
      FooterHomeOne,
    },
    data() {
      return {
        navs: [
          {
            name: "Home",
            path: "/",
          },
          {
            name: "About Us",
            path: "/about-us",
          },
          {
            name: "Blog",
            path: "/blog",
          },
          {
            name: "Contact",
            path: "/contact",
          },
          {
            name: "FAQs",
            path: "/faqs",
          },
        ],
      };
    },
  };
  </script>