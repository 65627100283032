var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"appie-hero-area"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v("Legal Documents")]),_c('ul',{staticClass:"links"},[_c('li',[_c('router-link',{attrs:{"to":{
            name: 'Privacy',
          }}},[_vm._v("Privacy Policy")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'TermsAndConditions',
          }}},[_vm._v("Terms and Conditions")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'AcceptableUse',
          }}},[_vm._v("Acceptable Use Policy")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'PrivacyRights',
          }}},[_vm._v("Privacy Rights Policy")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'Cookies',
          }}},[_vm._v("Cookies Policy")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'Accessibility',
          }}},[_vm._v("Accessibility Policy")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'TargetedAds',
          }}},[_vm._v("Targeted Ads Policy")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }