<template>
    <section class="appie-hero-area">
      <div class="container">
        <div style="margin-bottom: 30px">
          <h2>Accessibility Statement for Travlog</h2>
          <p><em>Effective Date: March 6th, 2024</em></p>
        </div>
        <ol>
          <li><h5>General</h5></li>
        </ol>
        <p>We at Travlog LLC, (“<u>Company</u>”, “<u>we</u>”, “<u>us</u>” or “<u>our</u>”) strive to ensure that our website located at <a href="http://www.travlogapp.com">hhtp://travlogapp.com</a> (“<u>Website</u>”) is accessible to all individuals, including persons with disabilities. We have invested significant resources to help ensure that our Website is made easier to use and more accessible for people with disabilities, with the strong belief that every person has the right to live with dignity, equality, comfort and independence. </p>
        <br>
        <ol>
          <li><h5>Accessibility on our Website</h5></li>
        </ol>
        <p>Our ongoing accessibility effort works towards conforming to the Web Content Accessibility Guidelines (WCAG) version 2.1, level AA criteria. These guidelines not only help make web content accessible to users with sensory, cognitive and mobility disabilities, but ultimately to all users, regardless of ability.</p>
        <br>
        <ol>
          <li><h5>Disclaimer</h5></li>
        </ol>
        <p>We continue our efforts to constantly improve the accessibility of our Website, in the belief that it is our collective moral obligation to allow seamless, accessible, and unhindered use for all people, including those with disabilities. Despite our efforts to make all pages and content on our Website fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.</p>
        <br>
        <ol>
          <li><h5>Here for You</h5></li>
        </ol>
        <p>If you are experiencing difficulty with any content on, or otherwise require assistance with, our Website, please contact us during normal business hours, as detailed below, and we will be happy to assist you.</p>
        <br>
        <ol>
          <li><h5>Contact Us</h5></li>
        </ol>
        <p>If you wish to report an accessibility issue, if have any questions, or if you need assistance, please contact us by email at <a href="mailto:support@travlogapp.com">support@travlogapp.com</a>.</p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "Accessibility",
  };
  </script>
  