<template>
  <div>
    <div v-if="posts.length !== 0">
      <div
        style="
          display: flex;
          justify-content: flex-start;
          column-gap: 5rem;
          flex-wrap: wrap;
        "
      >
        <div
          v-for="(blogPost, index) in posts"
          :key="index"
          style="display: inline-flex"
          class="col-lg-5"
        >
          <router-link
            :to="{
              name: 'SingleBlog',
              params: { id: blogPost.BlogID, title: blogPost.title },
            }"
          >
            <BlogItem :blogPost="blogPost" />
          </router-link>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <h1>SORRY, NO BLOGS EXIST. TRY AGAIN.</h1>
      </div>
    </div>
  </div>
</template>

<script>
import BlogItem from "./BlogItem/BlogItem.vue";
// import axios from "axios";

export default {
  metaInfo: {
    title: "Blog",
  },
  data() {
    return {
      blogPosts: [],
      showloading: true,
      current: 1,
      next: 2,
    };
  },
  components: { BlogItem },
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
    posts: [],
  },
  methods: {},
};
</script>

<style></style>
