<template>
    <PrivacyRights />
  </template>
  
  <script>
  import PrivacyRights from "../components/PrivacyRights/index";
  export default {
    components: { PrivacyRights },
  };
  </script>
  