<template>
    <section class="appie-hero-area">
      <div class="container">
        <div style="margin-bottom: 30px">
          <h2>Targeted Ads Preferences for Travlog</h2>
          <p><em>Effective Date: March 6th, 2024</em></p>
        </div>
        <ol>
          <li><h5>Your Privacy Choices to Opt-Out of Cross-Contextual Behavior Advertising</h5></li>
        </ol>
        <p>Travlog aims to be fully transparent about how we treat your personal information/data (“<u>Personal Information</u>”). Certain states, namely, California, Colorado, Connecticut, Utah, Virginia, give consumers of those states the right to opt out of certain data sharing practices that could constitute “selling,” “sharing,” or “profiling” (collectively, “<u>share</u>” or “<u>sharing</u>”) under these laws. On this page, you can learn about our data-sharing practices and exercise your right to opt out of certain data sharing practices concerning your personal information.</p>
        <p>Please follow the steps outlined in <u>all three sections below</u> (“Affiliate and Third-Party Preferences,” “Targeting Cookies Preferences,” and “Digital Vendor Preferences”) to fully exercise your privacy choices.</p>
        <br>
        <ol>
          <li><h5>Affiliate And Third-Party Preferences</h5></li>
        </ol>
        <p>To opt out of sharing your personal information with our affiliates and other companies for their own marketing purposes, please click the following link and fill out the form located on our Do Not Sell My Information page.</p>
        <br>
        <ol>
          <li><h5>Targeting Cookies Preferences</h5></li>
        </ol>
        <p>We allow our advertising partners to collect certain information through cookies and similar technologies on our Platform, which is used to show you ads that are targeted to your interests. To learn more about opting out of selling or sharing of information facilitated by these cookies, click the link below, please visit our <a href="https://www.travlogapp.com/cookies">Cookie Policy</a>.</p>
        <p>We may also recognize Global Privacy Control (GPC) signals from your web browser or web browser extension to automatically opt you out of the selling or sharing of information facilitated by these cookies. For more information about GPC, please visit <u>Global Privacy Control</u>.</p>
        <p><strong>IMPORTANT</strong>: Please note that you will need to repeat this process on each device, browser, and digital environment you use to access our Service. You will need to repeat this process again if you delete cookies on any of your browsers or devices. For information about our privacy practices, please visit our <a href="https://www.travlogapp.com/privacy">Privacy Policy</a>. </p>
        <br>
        <ol>
          <li><h5>Digital Vendor Preferences</h5></li>
        </ol>
        <p>Below are a few other choices you have to control sharing of your information in connection with targeted advertising:</p>
        <ul>
            <li>In your browser or mobile device (via settings), you can exercise choices related to sharing of device identifier information with app developers and other businesses for advertising purposes. The settings vary based on the browser or mobile platform you use (e.g., Apple or Android).</li>
            <li>Visit <a href="http://www.networkadvertising.org/choices/">NAI Opt Out</a> or <a href="http://www.aboutads.info/choices/">DAA Opt Out</a> to learn about other methods to opt out of interest-based advertising. Both organizations operate a centralized registry that displays and manages opt-out preferences for participating advertising-technology providers.</li>
        </ul>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "TargetedAds",
  };
  </script>
  