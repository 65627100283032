<script>
import axios from "axios";
export default {
  name: "CreatePost",
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        subject: "",
        messageBody: "",
      },
    };
  },
  methods: {
    submitForm() {
      const url = "https://api.web.travlogapp.com/info";

      axios.post(url, this.formData).catch((err) => console.log(err));

      alert("Email sent!");
    },
  },
  mounted() {
    var emailElements = document.querySelectorAll(".clickable-email");

    emailElements.forEach(function (element) {
      element.addEventListener("click", function () {
        var emailAddress = this.getAttribute("data-email");
        window.location.href = "mailto:" + emailAddress;
      });
    });
  },
};
</script>

<template>
  <!-- Contact Start -->
  <section class="contact-section" style="margin-top: 3%">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="contact--info-area">
            <h3>Get in touch</h3>
            <p>Looking for help? Fill the form and start a new adventure.</p>
            <div class="single-info">
              <h5>Headquaters</h5>
              <p>
                <i class="fal fa-home"></i>
                2233 S Dock St, Palmetto<br />
                Florida 34221
              </p>
            </div>
            <div class="single-info">
              <h5>Phone</h5>
              <p>
                <i class="fal fa-phone"></i>
                (914) 714 0115
              </p>
            </div>
            <div class="single-info">
              <h5>Support</h5>
              <p class="clickable-email" data-email="support@travlogapp.com">
                <i class="fal fa-envelope"></i> support@travlogapp.com
              </p>
            </div>
            <div class="single-info">
              <h5>Have Feedback?</h5>
              <p class="clickable-email" data-email="feedback@travlogapp.com">
                <i class="fal fa-envelope"></i> feedback@travlogapp.com
              </p>
            </div>
            <div class="ab-social">
              <h5>Follow Us</h5>
              <a
                class="fac"
                href="https://www.instagram.com/thetravlogapp?igsh=cTI3Yjl2a3ljODZm&utm_source=qr"
                ><i class="fab fa-instagram"></i
              ></a>
              <a class="twi" href="https://twitter.com/TheTravlogApp"
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="you" href="https:/www.youtube.com/@TheTravlogApp"
                ><i class="fab fa-youtube"></i
              ></a>
              <a class="lin" href="https://www.linkedin.com/company/travlog/"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="contact-form">
            <h4>Let’s Connect</h4>
            <p>Enter your information below:</p>
            <form class="row" id="form" @submit.prevent="submitForm">
              <div class="col-md-6">
                <input
                  type="text"
                  placeholder="First Name"
                  v-model="formData.firstName"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  placeholder="Last Name"
                  v-model="formData.lastName"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="email"
                  v-model="formData.emailAddress"
                  placeholder="Email Address"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="number"
                  v-model="formData.phoneNumber"
                  placeholder="Phone Number"
                />
              </div>
              <div class="col-md-12">
                <input
                  type="text"
                  v-model="formData.subject"
                  placeholder="Subject"
                />
              </div>
              <div class="col-md-12">
                <textarea
                  v-model="formData.messageBody"
                  placeholder="How can we help?"
                ></textarea>
              </div>
              <div class="col-md-6">
                <div class="condition-check">
                  <input id="terms-conditions" type="checkbox" required />
                  <label for="terms-conditions">
                    I agree to the
                    <a href="/terms-and-conditions" target="_blank"
                      >Terms & Conditions</a
                    >
                  </label>
                </div>
              </div>
              <div class="col-md-6 text-right">
                <input type="submit" value="Send Message" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact End -->
</template>

<style></style>
