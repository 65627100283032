<template>
  <Post />
</template>

<script>
import Post from "../components/PostOne/index";
export default {
  components: { Post },
};
</script>

<style></style>
