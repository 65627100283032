<template>
    <TargetedAds />
  </template>
  
  <script>
  import TargetedAds from "../components/TargetedAds/index";
  export default {
    components: { TargetedAds },
  };
  </script>
  