<template>
    <Accessibility />
  </template>
  
  <script>
  import Accessibility from "../components/Accessibility/index";
  export default {
    components: { Accessibility },
  };
  </script>
  