<template>
    <section class="appie-hero-area">
      <div class="container">
        <div style="margin-bottom: 30px">
          <h2>Acceptable Use Policy for Travlog</h2>
          <p><em>Effective Date: March 6th, 2024</em></p>
        </div>
        <p>Travlog's <a href="https://www.travlogapp.com/terms-and-conditions">Terms of Service</a> establish restrictions on content and conduct on Travlog: Social Scrapbook as outlined in this Acceptable Use Policy. In short, you may not use the Services, or assist or encourage any other party, to engage in any of the restricted Content or Conduct described below.</p>
        <ol>
          <li><h5>Content Restrictions</h5></li>
        </ol>
        <p>Prohibited content includes publishing, transmitting, distributing, or storing content, material, information or data that:</p>
        <p>
            <Strong>Illegal or Abusive</Strong>
        </p>
        <ul>
            <li>is illegal or promotes illegal activities, including in relation to export control or sanctions laws</li>
            <li>is threatening, harassing, abusive, hateful, discriminatory, or that advocates violence</li>
            <li>is sexually obscene or relates to sexual exploitation or abuse, including of minors</li>
            <li>may be harmful to minors</li>
            <li>significantly or repeatedly disrupts the experience of other users</li>
        </ul>
        <p>
            <Strong>Inauthentic, false, or infringing on rights</Strong>
        </p>
        <ul>
            <li>is fraudulent or contains false, deceptive or misleading statements, claims or representations—including misinformation, or impersonation such as of a person, company, or brand</li>
            <li>violates any privacy, publicity or other personal rights—including defamation, doxxing, posting media that you do not have permission to share, or otherwise sharing other people’s personal information without their consent</li>
            <li>nfringes, dilutes, misappropriates or otherwise violates any intellectual property, including copyrights, patents, trademarks, trade secrets or other proprietary information—including unauthorized use of domain names</li>
        </ul>
        <p>
            <Strong>Malicious, or harmful to systems</Strong>
        </p>
        <ul>
            <li>is harmful to or interferes with the Services or any third party’s networks, equipment, applications, services or websites (e.g., viruses, worms, Trojan horses, etc.)</li>
            <li>is intended to or likely to deceive, mislead or defraud others through phishing or social engineering techniques.</li>
        </ul>
        <br>
        <ol>
          <li><h5>Conduct Restrictions</h5></li>
        </ol>
        <p>Prohibited conduct includes:</p>
        <p>
            <Strong>Excessive automated, bulk, or spammy, or burdensome activity</Strong>
        </p>
        <ul>
            <li>using any automated system, including “robots,” “spiders,” “offline readers,” to access the Services in a manner that sends more requests to Travlog than a human can reasonably produce in the same period of time by using a conventional web browser</li>
            <li>creating accounts in bulk</li>
            <li>using extensions or other means to bypass limits on automated interactions such as kudos or comments</li>
            <li>taking any action that imposes, or may impose, at our sole discretion, an unreasonable or disproportionately large load on our infrastructure</li>
        </ul>
        <p>
            <Strong>Unauthorized access or use of information</Strong>
        </p>
        <ul>
            <li>accessing any content on the Services through any technology or means other than those provided or authorized by Travlog</li>
            <li>collecting or harvesting any information relating to an identified or identifiable individual, including account names and information about users of the Services, from the Services</li>
            <li>distributing, or disclosing any part of the Services in any medium, including by any automated or non-automated “scraping”</li>
            <li>using the Services in a manner for which they are not intended, at our sole discretion</li>
            <li>submitting to the Services or to Travlog any personally identifiable information, except as necessary for the establishment and operation of your account</li>
            <li>executing any form of network monitoring or running a network analyzer or packet sniffer or other technology to intercept, decode, mine or display any packets used to communicate between the Service's servers or any data not intended for you.</li>
        </ul>
        <p>
            <Strong>Harmful or malicious activity against systems or Services</Strong>
        </p>
        <ul>
            <li>attempting to disrupt, degrade, impair or violate the confidentiality, integrity or availability of the Services or the computers, services, accounts or networks of any other party (including denial of service attacks), including any activity that typically precedes attempts to breach security such as scanning, probing or other testing or vulnerability assessment activity, or engaging in or permitting any network or hosting activity that results in a deny list or other blockage of Travlog internet protocol space</li>
        </ul>
        <p>
            <Strong>Unauthorized use of the Services</Strong>
        </p>
        <ul>
            <li>accessing the Services to monitor its availability, performance or functionality</li>
            <li>permitting any third party to access the Services unless approved by Travlog</li>
            <li>using, copying, modifying, creating a derivative work of, reverse engineering, decompiling or otherwise attempting to extract the source code of the software underlying the Services or any part thereof, unless expressly permitted or required by law, and in any case, without providing prior written notice to Travlog</li>
            <li>copying, framing or mirroring any part of the Services</li>
            <li>bypassing the measures we may use to prevent or restrict access to the Services, including features that prevent or restrict use or copying of any content</li>
            <li>removing any copyright, trademark or other proprietary rights notices contained in or on the Services</li>
            <li>evading an enforcement action, for example by creating a new account after being suspended.</li>
        </ul>
        <br>
        <ol>
          <li><h5>Reporting Content</h5></li>
        </ol>
        <p>We provide a number of ways for users to report content on Travlog that they believe violates the law, this Acceptable Use Policy or any other part of Travlog’s <a href="https://www.travlogapp.com/terms-and-conditions">Terms of Service</a>.</p>
        <p>In the Travlog mobile application, users can click the report option next to the content or user account. From there, users will be able to access a reporting flow to provide reviewers with more context about the nature of their report.</p>
        <p>Please contact <a href= "mailto:support@travlogapp.com">support@travlogapp.com</a>  for any reporting outside of the mobile application.</p>
        <ol>
          <li><h5>Content Review and Enforcement</h5></li>
        </ol>
        <p>We take user reports seriously and take appropriate actions based on our policies, taking context into account where relevant, and the results of our investigation. We may proactively review content to determine whether it violates our policies. In some cases, we may use automated detection to surface content for human review, or in limited cases, to block content. In all cases, a human reviews any appeals.</p>
        <p>If we determine that content violates our Terms of Service, including our Acceptable Use Policy, we may take action such as to remove content, to revoke or limit access to features, and/or to suspend or block account access.</p>
        <br>
        <ol>
          <li><h5>Appeals</h5></li>
        </ol>
        <p>When we remove content, suspend an account, restrict access to features, or take any other action against a user’s content or account, we notify the account owner using the email address on file. These notices offer the opportunity for users to appeal these decisions by getting in touch with human reviewers who can read and evaluate their appeals.</p>
        <p>If the user wishes to appeal an enforcement decision against their content or account, they can contact us via email at <a href= "mailto:support@travlogapp.com">support@travlogapp.com</a> to provide information explaining why they believe the enforcement action was taken in error. When submitting an appeal, users should tell us the reason they are appealing the decision, including any context or background information that could help us understand their case. They may submit screenshots or any additional content they believe will be helpful to our reviewers to consider.</p>
        <p>Once an appeal is received, a human will review the original decision against our policies and guidelines to determine whether the determination of a violation and/or the enforcement action taken were warranted. Such decisions typically include granting the appeal, requesting more information, or denying the appeal.</p>
        <p>We will communicate our decision by responding to the email we received from the appealing user.</p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "AcceptableUse",
  };
  </script>
  