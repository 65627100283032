<template>
  <section class="appie-team-area appie-team-about-area pb-90 pt-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">Meet The Team</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div
            class="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div class="thumb">
              <img src="@/assets/images/Matt.jpg" alt="" />
            </div>
            <div class="content text-left">
              <h5 class="title">Matthew Castellucci</h5>
              <span>Founder</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <div class="thumb">
              <img src="@/assets/images/Shivansh.png" alt="" />
            </div>
            <div class="content text-left">
              <h5 class="title">Shivansh Singh</h5>
              <span>Developement</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="800ms"
          >
            <div class="thumb">
              <img src="@/assets/images/Joseph.png" alt="" />
            </div>
            <div class="content text-left">
              <h5 class="title">Joseph Cittadino</h5>
              <span>Operations</span>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-12">
                    <div class="team-btn text-center mt-50">
                        <a class="main-btn" href="#"> View all Members <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
