<template>
  <section class="appie-hero-area">
    <div class="container">
      <div style="margin-bottom: 30px">
        <h2>Privacy Policy for Travlog</h2>
        <p><em>Effective Date: March 6th, 2024</em></p>
        <p>BY USING OR ACCESSING OUR PLATFORM, YOU ARE ACCEPTING THE PRACTICES DESCRIBED IN THIS PRIVACY POLICY.</p>
      </div>

      <ol>
        <li><h5>General</h5></li>
      </ol>
      <p>Travlog LLC, a Florida limited liability company (“<u>Company</u>” or “<u>Travlog</u>” or “<u>we</u>” or “<u>us</u>” or “<u>our</u>”) respects the privacy of its users (“<u>User(s)</u>” or “<u>you</u>”) that use our website https://www.travlogapp.com, any site or sub-site hosted thereon, and any mobile applicable or other software provided by us (collectively, the “<u>Platform</u>” or “<u>>Service</u>”).</p>
      <p>The following privacy policy (“<u>Privacy Policy</u>”) is designed to inform you, as a User of the Platform, about the types of information that the Company may gather about or collect from you in connection with your use thereof. It also is intended to explain the conditions under which Company uses and discloses that information, and your rights in relation to that information. Changes to this Privacy Policy are discussed at the end of this document. Each time you use the Platform, however, the current version of this Privacy Policy will apply. Accordingly, each time you use the Platform you should check the date of this Privacy Policy (which appears at the end of this document) and review any changes since your last visit. </p>
      <p>This Privacy Policy is expressly incorporated into our <a href="https://www.travlogapp.com/terms-and-conditions">Terms of Service</a>, which can be accessed by clicking the links contained herein. Any defined or capitalized terms not otherwise defined herein shall have the meaning ascribed to them in our <a href="https://www.travlogapp.com/terms-and-conditions">Terms of Service</a>. </p>
      <p>Please note that this Privacy Policy does not apply to the practices of third parties who you may choose to share information with when you use the Platform. Please note that the collection and use of your information by these third parties is subject to their own privacy policies and terms. You should understand the privacy and security practices of any third party before you share information with them.</p>
      <p>The Platform is hosted in the United States of America and is subject to U.S. state and federal law. If you are accessing our Platform from other jurisdictions, please be advised that you are transferring your personal information to us in the United States, and by using our Platform, you consent to that transfer and use of your personal information in accordance with this Privacy Policy. You also agree to abide by the applicable laws of applicable states and U.S. federal law concerning your use of the Platform and your agreements with us. Any persons accessing our Platform from any jurisdiction with laws or regulations governing the use of the Internet, including personal data collection, use and disclosure, different from those of the jurisdictions mentioned above may only use the Platform in a manner lawful in their jurisdiction. If your use of the Platform would be unlawful in your jurisdiction, please do not use the Platform.</p>
      <p>From time to time, we may want to contact you with information about announcements, Platform updates, promotions, and special offers. We also may want to contact you with information about products and services from our business partners. You may opt out of such communications at any time by clicking the “unsubscribe” link found within the Company’s emails and changing your contact preferences. </p>
      <p>For EEA Users: We only send marketing communications to Users located in the EEA with your prior consent. Please see the section “For EEA Users” below.</p>
      <ol start="2">
        <li>
          <h5>Gathering, Use and Disclosure of Non-Personally-Identifying Information</h5>
        <ol>
          <br>
          <li><Strong>Users of the Platform Generally</Strong></li>
        </ol>
        <p>“Non-Personally-Identifying Information” is information that, without the aid of additional information, cannot be directly associated with a specific person. “Personally-Identifying Information,” by contrast, is information such as a name or email address that, without more, can be directly associated with a specific person. Like most website operators, the Company gathers from Users Non-Personally-Identifying Information of the sort that web browsers and mobile device applications, depending on their settings, may make available. That information includes a User’s Internet Protocol (IP) address, operating system, browser type and the locations of websites or apps the User views right before arriving at, while navigating and immediately after leaving the Platform, as more fully described herein. Although such information is not Personally-Identifying Information, it may be possible for Company to determine from an IP address a User’s internet service provider and the geographic location of the visitor’s point of connectivity as well as other statistical usage data. Company may analyze Non-Personally-Identifying Information gathered from Users to help the Company better understand how the Platform is being used. By identifying patterns and trends in usage, Company is able to better design the Platform to improve Users’ experiences, both in terms of content and ease of use. From time to time, Company may also release the Non-Personally-Identifying Information gathered from Users in the aggregate, such as by publishing a report on trends in the usage of the Platform.</p>
        <ol>
          <li><Strong>Automatically Collected Information</Strong></li>
        </ol>
        <p>Certain data about the devices you use to connect with our Platform and your use thereof are automatically logged in our systems, including:</p>
        <p>
          <i>Mobile Device Information</i> - If you use a mobile device to access the Platform, we may collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and IP address.
        </p>
        <p>
          <i>Log data</i> - As with most websites and technology services delivered over the internet, our servers automatically collect data when you access or use our Platform and record it in log files. This log data may include the IP address, browser type and settings, the date and time of use, information about browser configuration, language preferences, and cookie data.
        </p>
        <p>
          <i>Usage information</i> - This is information about the Platform and how you use it. We may also obtain data from our third-party partners and service providers to analyze how Users use our Platform. For example, we will know how many Users access a specific page on the Platform and which links they clicked on. We use this aggregated information to better understand and optimize the Platform.
        </p>
        <p>
          <i>Device information</i> - This is data from your computer or mobile device, such as the type of hardware and software you are using (for example, your operating system and browser type), as well as unique device identifiers for devices that are using our software.
        </p>
        <p>
          <i>Web Cookies</i> - Data obtained from cookies are described below and in our <a href="https://www.travlogapp.com/cookies">Cookie Policy</a>.
        </p>
        <ol>
          <li><Strong>Web Cookies</Strong></li>
        </ol>
        <p>Web Cookies are small text files stored on your device and used by web browsers to deliver personalized content and remember logins and account settings. The Company uses Web Cookies and similar technologies, including tracking pixels and web beacons, to collect usage and analytic data that helps us provide our Platform to you, as well as to help deliver ads for relevant products and services to you when you visit certain pages on the Platform and then visit certain third-party sites. For more information on cookies and how the Company uses them, please see our <a href="https://www.travlogapp.com/cookies">Cookie Policy</a>. </p>
        <p>PLATFORM USERS WHO DO NOT WISH TO HAVE WEB COOKIES PLACED ON THEIR COMPUTERS SHOULD SET THEIR BROWSERS TO REFUSE WEB COOKIES BEFORE ACCESSING THE PLATFORM, WITH THE UNDERSTANDING THAT CERTAIN FEATURES OF THE PLATFORM MAY NOT FUNCTION PROPERLY WITHOUT THE AID OF WEB COOKIES. PLATFORM USERS WHO REFUSE WEB COOKIES ASSUME ALL RESPONSIBILITY FOR ANY RESULTING LOSS OF FUNCTIONALITY.</p>
        <ol>
          <li><Strong>Web Beacons</Strong></li>
        </ol>
        <p>A “Web Beacon” is an object that is embedded in a web page or email that is usually invisible to the user and allows website operators to check whether a user has viewed a particular web page or an email. Company may use Web Beacons on the Platform and in emails to count Users who have visited particular pages, viewed emails and to deliver co-branded services. Web Beacons are not used to access Users’ Personally-Identifying Information. They are a technique that the Company may use to compile aggregated statistics about Platform usage. Web Beacons collect only a limited set of information, including a number, time and date of a page or email view and a description of the page or email on which the Web Beacon resides. You may not decline Web Beacons. However, they can be rendered ineffective by declining all Web Cookies or modifying your browser setting to notify you each time a Web Cookie is tendered, permitting you to accept or decline Web Cookies on an individual basis.</p>
        <ol>
          <li><Strong>Analytics</Strong></li>
        </ol>
        <p>We may use third-party vendors, including Google, who use first-party Web Cookies (such as the Google Analytics cookie) and third-party Web Cookies (such as the DoubleClick cookie) together to inform, optimize and serve ads based on your past activity on the Platform, including Google Analytics for Display Advertising. The information collected may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. If you do not want any information to be collected and used by Google Analytics, you can install an opt-out in your web browser (<a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout</a>) and/or opt out from Google Analytics for Display Advertising or the Google Display Network by using Google’s Ads Settings (<a href="www.google.com/settings/ads">www.google.com/settings/ads</a>). More information on Web Cookies can be found in our <a href="https://www.travlogapp.com/cookies">Cookie Policy</a>.	</p>
        <ol>
          <li><Strong>Aggregated and Non-Personally-Identifying Information</Strong></li>
        </ol>
        <p>We may share aggregated and Non-Personally Identifying Information we collect under any of the above circumstances. We may also share it with third parties and our affiliate companies to develop and deliver targeted advertising on the Platform and on websites of third parties. We may combine Non-Personally Identifying Information we collect with additional Non-Personally Identifying Information collected from other sources. We also may share aggregated information with third parties, including advisors, advertisers, and investors, for the purpose of conducting general business analysis. For example, we may tell our advertisers the number of visitors to the Platform and the most popular features or services accessed. This information does not contain any Personally-Identifying Information and may be used to develop Platform content and services that we hope you and other Users will find of interest and to target content and advertising.</p>
        <ol>
          <li><Strong>Additional Terms for Mobile Device Users</Strong></li>
        </ol>
        <p><i>Geo-Location Information</i> - Unless we have received your prior consent, we do not access or track any location-based information from your mobile device at any time while accessing our Platform on your mobile device, except that it may be possible for Company to determine from an IP address the geographic location of your point of connectivity, in which case we may gather and use such general location data.</p>
        <p><i>Social Media</i> - We may use hyperlinks on the Platform which will redirect you to a social network if you click on the respective link. However, when you click on a social plug-in, such as Facebook’s “Like” button, Twitter’s “tweet” button or the Google+, that particular social network’s plugin will be activated, and your browser will directly connect to that provider’s servers. If you do not use these buttons, none of your data will be sent to the respective social network’s plugin provider. So, for example, when you click on the Facebook’s “Like” button on the Platform, Facebook will receive your IP address, the browser version and screen resolution, and the operating system of the device you have used to access the Platform. Settings regarding privacy protection can be found on the websites or applications of these social networks and are not within our control. Please be aware that when you use third-party sites or services, their own terms and privacy policies will govern your use of those sites or services.</p>
      </li>
    </ol>
      <ol start="3">
        <li>
          <h5>Collection, Use and Disclosure Of Personally-Identifying Information</h5>
        </li>
        <br>
        <li>
          <Strong>Generally</Strong>
        </li>
        <p>“Non-Personally-Identifying Information” is information that, without the aid of additional information, cannot be directly associated with a specific person. “Personally-Identifying Information,” by contrast, is information such as a name or email address that, without more, can be directly associated with a specific person. Like most website operators, the Company gathers from Users Non-Personally-Identifying Information of the sort that web browsers and mobile device applications, depending on their settings, may make available. That information includes a User’s Internet Protocol (IP) address, operating system, browser type and the locations of websites or apps the User views right before arriving at, while navigating and immediately after leaving the Platform, as more fully described herein. Although such information is not Personally-Identifying Information, it may be possible for Company to determine from an IP address a User’s internet service provider and the geographic location of the visitor’s point of connectivity as well as other statistical usage data. Company may analyze Non-Personally-Identifying Information gathered from Users to help the Company better understand how the Platform is being used. By identifying patterns and trends in usage, Company is able to better design the Platform to improve Users’ experiences, both in terms of content and ease of use. From time to time, Company may also release the Non-Personally-Identifying Information gathered from Users in the aggregate, such as by publishing a report on trends in the usage of the Platform.</p>
        <li>
          <Strong>Company Communications</Strong>
        </li>
        <p>We may occasionally use your name and email address to send you notifications regarding new services and products that we think you may find valuable. We may also send you service-related announcements from time to time through the general operation of the Platform. Generally, you may opt out of such emails through an unsubscribe link contained in our emails or by emailing us at <a href="mailto:support@travlogapp.com">support@travlogapp.com</a>. </p>
        <li>
          <Strong>Generally</Strong>
        </li>
        <p>We will disclose Personally-Identifying Information under the following circumstances:</p>
        <p><i>By Law or to Protect Rights</i> - When we believe disclosure is appropriate, we may disclose Personally-Identifying Information in connection with efforts to investigate, prevent or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of Company, our Users, our employees or others; to comply with applicable law or cooperate with law enforcement; to enforce our agreements or policies, in response to a subpoena or similar investigative demand, a court order or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us. </p>
        <p><i>Business Partners</i> - Travlog may work closely with a number of business partners, such as our payment partners, that assist us with providing the Service in accordance with our <a href="https://www.travlogapp.com/terms-and-conditions">Terms of Service</a>. When one of these companies assists us in facilitating your transaction, we, or they, may need to share information related to the transaction. These affiliated businesses may sell items or services to you through the Service or offer promotions (including email promotions). In each case, these partners will process your personal information in accordance with their own privacy policies. We encourage you to read the privacy policies and ask questions of third parties before you disclose your personal information to them. </p>
        <p><i>Marketing Communications</i> - Unless Users opt-out from receiving Company marketing materials as provided for herein, Company may email Users about products and services that Company believes may be of interest to them. If you wish to opt-out of receiving marketing materials from Company, you may do so by following the unsubscribe link in the email communications or contacting us using the contact information below. </p>
        <p><i>Advertising</i> - Ads appearing on our Platform may be delivered to you by advertising partners, who may embed or set Cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile Non-Personally-Identifying Information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy does not cover the use of cookies by any advertisers.</p>
        <p>Some of the ads on our Platform may also be served by Google. Google's use of the DART Cookie enables it to serve ads to you based on your visit to our Platform and other sites on the Internet. DART uses Non-Personally-Identifying Information and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART Cookie by visiting <a href="https://policies.google.com/technologies/ads">Google’s Ad and Content Network Privacy Policy</a>. For more information, please review our <a href="https://www.travlogapp.com/cookies">Cookie Policy</a> and <a href="https://www.travlogapp.com/targetedads">Targeted Ads Preference</a>.</p>
        <p><i>Third-Party Service Providers</i> - We may share your Personally-Identifying Information, which may include your name and contact information (including email address) with our authorized third-party service providers that perform certain services on our behalf. These services may include providing customer service and marketing assistance, performing business and sales analysis, supporting the Platform’s functionality, and supporting any beta services, contests, sweepstakes, surveys, and other features that may be offered through the Platform from time to time. Such third-party service providers referenced herein may have access to Personally-Identifying Information needed to perform their agreed-upon services. You acknowledge and agree that each third-party service provider to whom Personally-Identifying Information is shared may have their own privacy practices and Company disclaims any liability for the re-use or dissemination of any Personally-Identifying Information by any third-party with whom your Personally-Identifying Information is shared.</p>
        <p><i>Business Transfers; Bankruptcy</i> - Company reserves the right to transfer all Personally-Identifying Information in its possession to a successor organization in the event of a merger, acquisition, bankruptcy or other sale of all or a portion of Company’s assets. Other than to the extent ordered by a bankruptcy or other court, the use and disclosure of all transferred Personally-Identifying Information will be subject to this Privacy Policy, or to a new privacy policy if you are given notice of that new privacy policy and are given an opportunity to affirmatively opt-out of it. Personally-Identifying Information submitted or collected after a transfer, however, may be subject to a new privacy policy adopted by the successor organization.</p>
        <li>
          <Strong>Changing Personally-Identifying Information</Strong>
        </li>
        <p>You may at any time review or change your Personally-Identifying Information by contacting us using the contact information below. Upon your request, we will deactivate or delete your contact information from our active databases. Such information will be deactivated or deleted as soon as practicable based on your activity and in accordance with our deactivation policy and applicable law. We may retain in our files some Personally-Identifying Information to prevent fraud, to troubleshoot problems, to assist with any investigations, to enforce our Privacy Policy, and to comply with legal requirements as is permitted by law. Therefore, you should not expect that all your Personally-Identifying Information will be completely removed from our databases in response to your requests. Additionally, we keep a history of changed information to investigate suspected fraud in connection with the use of our Platform.</p>
        <li>
          <Strong>General Use</Strong>
        </li>
        <p>The Company uses the Personally-Identifying Information in the file we maintain about you, and other information we obtain from your current and past activities on the Platform (1) communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest to you either from us, our affiliate companies or other third parties; (2) to develop and display content and advertising tailored to your interests on the Platform and other sites; (3) to resolve disputes and troubleshoot problems; (4) to measure consumer interest in our Platform; (5) to inform you of updates; (6) to customize your experience; (7) to detect and protect us against error, fraud and other criminal activity; (8) to enforce our <a href="https://www.travlogapp.com/terms-and-conditions">Terms of Service</a>; and (9) to do as otherwise described to you at the time of collection. At times, we may look across multiple Users to identify problems. In particular, we may examine your Personally-Identifying Information to identify Users using multiple User IDs or aliases. We may compare and review your Personally-Identifying Information for accuracy and to detect errors and omissions. We may use financial information or payment method to process payment for any purchases made on the Platform to protect against or identify possible fraudulent transactions and otherwise as needed to manage our business.</p>
        <li>
          <Strong>Privacy Policy Changes</Strong>
        </li>
        <p>Company may, in its sole discretion, change this Privacy Policy from time to time. Any and all changes to Company’s Privacy Policy will be reflected on this page and the date new versions are posted will be stated at the top of this Privacy Policy. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you. Users should regularly check this page for any changes to this Privacy Policy. Company will always post new versions of the Privacy Policy on the Platform. However, Company may, as determined in its discretion, decide to notify Users of changes made to this Privacy Policy via email or otherwise. Accordingly, it is important that Users always maintain and update their contact information when changes go into effect.</p>
        <li>
          <Strong>Security</Strong>
        </li>
        <p>We take the security of your Personally-Identifying Information seriously and use reasonable electronic, personnel and physical measures to protect it from loss, theft, alteration, or misuse. However, please be advised that even the best security measures cannot fully eliminate all risks. We cannot guarantee that only authorized persons will view your information. We are not responsible for third-party circumvention of any privacy settings or security measures.</p>
        <li>
          <Strong>Ad Management Partners</Strong>
        </li>
        <p>The following are a list of partners (“<u>Travlog Partners</u>”) with whom Travlog may share the following data when providing ad management services to third-party publishers:</p>
        <ul>
          <li>Google - You may find Google’s privacy policy through this link.</li>
        </ul>
        <p>Travlog and its advertising partners collect and process the following information through the use of cookies, pixels, or server-to-server connections. None of the information listed below is retained by Travlog after it is processed, and an advertisement is served.  The information collected is only used for the listed purpose.</p>
        <ul>
          <li>Advertising Identifier</li>
          <li>IP Address</li>
          <li>Operating System type</li>
          <li>Operating System Model</li>
          <li>Device Type and Model</li>
          <li>Cookie Information</li>
          <li>Language of the website</li>
          <li>Web browser type</li>
          <li>Email</li>
        </ul>
        <p>Travlog Partners may also use this information to link to other end user information the partner has independently collected to deliver targeted advertisements. Travlog Partners may also separately collect data about end users from other sources, such as advertising IDs or pixels, and link that data to data collected from Travlog advertisers and publishers in order to provide interest-based advertising across your online experience, including devices, browsers and apps. This data includes cookie information, device information, usage data, information about interactions between users and advertisements and websites, geolocation data, traffic data, and information about a visitor’s referral source to a particular website.  Travlog Partners may also create unique IDs to create audience segments, which are used to provide targeted advertising. For more information, please visit our <a href="https://www.travlogapp.com/cookies">Cookie Policy</a>, to which you expressly agree when using our Platform.</p>
        <p>If you would like more information about this practice and to know your choices to opt-in or opt-out of this data collection, please visit the <a href="https://thenai.org/opt-out/">Network Advertising Initiative</a>.  You may also visit <a href="https://optout.aboutads.info/?c=2&lang=EN">Your Ad Choices</a> and the <a href="https://optout.networkadvertising.org/?c=1">NAI Opt-Out Page</a> to learn more information about interest-based advertising. You may download the <a href="https://youradchoices.com/appchoices">AppChoices</a> app to opt out in connection with mobile apps or use the platform controls on your mobile device to opt out.  For more information about cookies and how to disable them, you can consult the information at <a href="https://allaboutcookies.org/how-to-manage-cookies">All About Cookies</a>.</p>
        <li>
          <Strong>Children’s Online Privacy Protection</Strong>
        </li>
        <p>The Children’s Online Privacy Protection Act (“COPPA”) protects the online privacy of children under thirteen (13) years of age. We do not knowingly collect or maintain Personally-Identifying Information from anyone under the age of thirteen (13), unless or except as permitted by law. Any person who provides Personally-Identifying Information through the Platform represents to us that he or she is at least thirteen (13) years of age or older. If we learn that Personally-Identifying Information has been collected from a User under thirteen (13) years of age on or through the Platform, then we will take the appropriate steps to cause this information to be deleted. If you are the parent or legal guardian of a child under thirteen (13) who has become a User or has otherwise transferred Personally-Identifying Information to us, please contact Company using our contact information below to have that child’s information deleted.</p>
        <li>
          <Strong>State-Specific Notices</Strong>
        </li>
        <p>For Users are California, Colorado, Connecticut, Nevada, Utah, and Virginia, please read our <a href="https://www.travlogapp.com/privacyrights">Privacy Rights Summary Notice</a> and <a href="https://www.travlogapp.com/targetedads">Targeted Ad Preference</a>.</p>
        <li>
          <Strong>Additional Rights and Choices for California Residents.</Strong>
        </li>
        <p>If you are a California resident, the following additional rights may be available to you.</p>
        <li>
          <Strong>Shine the Light.</Strong>
        </li>
        <p>Users who are California residents may request (i) a list of categories of personal information disclosed to third parties during the immediately preceding calendar year for those third parties’ own direct marketing purposes; and (ii) a list of the categories of third parties to whom we disclosed such information. To exercise a request, please send us an email or a letter to the addresses in the section entitled “Contact Us” below and specify you are making a “California Shine the Light” request. We may require additional information from you to allow us to verify your identity and are only required to respond to requests once during any calendar year.</p>
        <li>
          <Strong>California Consumer Privacy Act of 2018 (CCPA).</Strong>
        </li>
        <p>Beginning January 1, 2020, the CCPA provides California residents with additional rights as described below. Please note your right to know and right to delete are subject to certain exceptions under the CCPA.</p>
        <li>
          <Strong>Notice of Collection</Strong>
        </li>
        <p>During the past twelve (12) months, we have collected the following categories of personal information that the CCPA covers:</p>
        <ul>
          <li>Identifiers, including name, email address, date of birth, phone number, IP address, and an ID or number assigned to you.</li>
          <li>Consumer records, billing and delivery address, and credit or debit card information.</li>
          <li>Commercial information, including purchases and engagement with our platforms and mobile applications.</li>
          <li>Internet activity, including your interactions with our platforms and mobile applications.</li>
          <li>Audio data, including information collected for customer care quality assurance</li>
          <li>Geolocation data, including location enabled services such as GPS.</li>
          <li>Inferences, including information about your interests, preferences, and favorites.</li>
          <li>This privacy statement includes additional information about our data practices. For more information on information we collect, including the sources we receive information from, review this Privacy Policy in its entirety. We collect and use these categories of personal information for the business purposes described herein, including to provide and manage our platform. </li>
        </ul>
        <li>
          <Strong>Right to Know</Strong>
        </li>
        <p>You have the right to know certain information about our data practices in the preceding twelve (12) months. You have the right to request the following information from us:</p>
        <ul>
          <li>The categories of personal information we have collected about you;</li>
          <li>The categories of sources from which the personal information was collected;</li>
          <li>The categories of personal information about you we disclosed for a business purpose or sold;</li>
          <li>The categories of third parties to whom the personal information was disclosed for a business purpose or sold;</li>
          <li>The business or commercial purpose for collecting or “selling” the personal information; and</li>
          <li>The specific pieces of personal information we have collected about you.</li>
        </ul>
        <li>
          <Strong>Right to Delete</Strong>
        </li>
        <p>You have the right to request the deletion of personal information that we collect or maintain about you.</p>
        <li>
          <Strong>Right to Opt-Out of “Sale”</Strong>
        </li>
        <p>We do not sell information as the term “sell” is traditionally understood. However, we may disclose certain information about you when you use our Platform for purposes that may be considered a “sale” under the CCPA. For example, we may disclose information to advertising partners, advertising technology companies, and companies that perform advertising-related services in order to provide you with more relevant advertising tailored to your interests. This information may include identifiers such as your IP address, advertising identifiers, commercial information, your internet, or other electronic network information such as your interaction with an ad, geolocation data and inferences. To the extent the Company sells your personal information as the term “sell” is defined under the CCPA, you have the right to opt-out of the sale of your personal information by us to third parties at any time. You may submit a request to opt-out by clicking Do Not Sell My Personal Information and following the instructions, or by contacting us as set forth below. </p>
        <li>
          <Strong>How to Exercise Your CCPA Rights.</Strong>
        </li>
        <p>To exercise any of these rights, please email us at <a href="mailto:support@travlogapp.com">support@travlogapp.com</a>. In the request, please specify which right you are seeking to exercise and the scope of the request. We will confirm receipt of your request within ten (10) days. We may require specific information from you to help us verify your identity and process your request. This is a security measure to help ensure that your personal information is not disclosed to someone that does not have the right to receive it. If we are unable to verify your identity, we may deny your requests to know or delete.</p>
        <p>If you choose to exercise any of these rights, you will not receive different prices or quality of services unless permitted by applicable law, including if those difference are reasonable related to your information. </p>
        <p>Please note that if you opt out of receiving one form of communication, it does not mean you’ve opted out of other forms as well. For example, if you opt out of receiving marketing emails, you may still receive marketing text messages if you’ve opted in to receiving them. </p>
        <li>
          <Strong>For EEA Users</Strong>
        </li>
        <p>Individuals located in the European Economic Area (EEA) have certain rights in respect to their personal information, including the right to access, correct, or delete Personally-Identifying Information we process through your use of the Platform. If you’re a User based in the EEA, you can:</p>
        <ul>
          <li>Request a Personally-Identifying Information report by emailing us at the address provided below. This report will include the Personally-Identifying Information we have about you, provided to you in a structured, commonly used, and portable format. Please note that the Company may request additional information from you to verify your identity before we disclose any information.</li>
          <li>Have your Personally-Identifying Information corrected or deleted. Some Personally-Identifying Information can be updated by you: You can update your name and email address, as well as language preference, by emailing us at the address providing below.</li>
          <li>Object to us processing your Personally-Identifying Information. You can ask us to stop using your Personally-Identifying Information, including when we use your Personally-Identifying Information to send you marketing emails. We only send marketing communications to Users located in the EEA with your prior consent, and you may withdraw your consent at any time.</li>
          <li>Request that we transfer your Personally-Identifying Information to another person by e-mailing us at the e-mail address listed at the bottom of this Privacy Policy.</li>
          <li>Complain to a regulator. If you are based in the EEA and believe that we have not complied with data protection laws, you have a right to lodge a complaint with your local supervisory authority.</li>
        </ul>
        <li>
          <Strong>Do-Not-Track Policy</Strong>
        </li>
        <p>Most web browsers and some mobile operating systems include a Do-Not-Track (“<u>DNT</u>”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. Because there is not yet a common understanding of how to interpret the DNT signal, the Platform currently does not respond to DNT browser signals or mechanisms.</p>
        <li>
          <Strong>Contact Us</Strong>
        </li>
        <p>If you have any questions regarding our Privacy Policy, please contact us by email at <a href="mailto:support@travlogapp.com">support@travlogapp.com</a> or by mail at 6908 Stones Throw Cir N, Apt 10204, Saint Petersburg, FL 33710. </p>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  name: "Privacy",
};
</script>
