<template>
    <Cookies />
  </template>
  
  <script>
  import Cookies from "../components/Cookies/index";
  export default {
    components: { Cookies },
  };
  </script>
  