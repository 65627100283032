<template>
    <section class="appie-hero-area">
      <div class="container">
        <div style="margin-bottom: 30px">
          <h2>Cookie Policy for Travlog</h2>
          <p><em>Effective Date: March 6th, 2024</em></p>
        </div>
        <ol>
          <li><h5>Introduction to Cookies</h5></li>
        </ol>
        <p>This Cookie Policy (“<strong>Cookie Policy</strong>”) provides detailed information about why, how, and when we use Cookies on our Platform and/or Services, as defined in our <a href="https://www.travlogapp.com/terms-and-conditions">Terms of Service</a> and <a href="https://www.travlogapp.com/privacy">Privacy Policy</a>. Any capitalized terms used in this Cookie Policy but not defined herein, have the meanings set forth in in our Terms of Service.  By accessing our Platform, you agree to our use of Cookies, as further set forth herein. If you are accessing our Platform via any other third-party application or site, you expressly agree to be bound by the notices, terms, and conditions posted thereon.</p>
        <br>
        <ol>
          <li><h5>What is a Cookie</h5></li>
        </ol>
        <p>A Cookie is a small text file that is placed on your hard drive by a web page server. Cookies contain information that can later be read by a web server in the domain that issued the Cookie to you. Some of the Cookies will only be used if you use certain features or select certain preferences, and some Cookies are essential to the Platform and will always be used. Web beacons, tags, and scripts may be used in the Platform or in emails to help us deliver Cookies and count visits, understand usage and campaign effectiveness, and determine whether an email has been opened and acted upon. We may receive reports based on the use of these technologies by our service/analytics providers (for example, Google Analytics) on an individual and aggregated basis.</p>
        <br>
        <ol>
          <li><h5>Why does Travlog use Cookies?</h5></li>
        </ol>
        <p>Travlog and our marketing partners, affiliates, and analytics or service providers use Cookies and other technologies to ensure everyone who uses the Platform has the best possible experience. For example, when you use our Platform, we may place a number of Cookies in your browser. We use these Cookies to enable us to hold session information as you navigate from page to page within the Platform, improve your experience, and track and analyze usage and other statistical information. If you elect not to activate the Cookie or to later disable Cookies, you may still visit our Platform, but your ability to use some features or areas of those offerings may be limited.</p>
        <br>
        <ol>
          <li><h5>How to Disable Cookies.</h5></li>
        </ol>
        <p>You can generally activate or later deactivate the use of Cookies through a functionality built into your web browser. To learn more about how to control Cookie settings through your browser:</p>
        <ul>
            <li>Click <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">here</a> to learn more about managing Cookie settings in Mozilla Firefox</li>
            <li>Click <a href="https://support.google.com/chrome/answer/95647">here</a> to learn more about managing Cookie settings in Google Chrome</li>
            <li>Click <a href="http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies">here</a> to learn more about managing Cookie settings in Microsoft Internet Explorer</li>
            <li>Click <a href="https://support.apple.com/en-lb/guide/safari/sfri11471/16.0/mac/11.0">here</a> to learn more about managing Cookie settings in Safari</li>
            <li>Click <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">here</a> to learn more about managing Cookie settings in Microsoft Edge</li>
        </ul>
        <p>If you want to learn more about Cookies or how to control, disable, or delete them, please visit <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> for detailed guidance. In addition, certain third-party advertising networks, including Google, permit users to opt out of or customize preferences associated with your Internet browsing. To learn more about this feature from Google, click here.</p>
        <ol>
          <li><h5>Types of Cookies that Travlog Uses.</h5></li>
        </ol>
        <p>Many jurisdictions require or recommend that website operators inform users/visitors as to the nature of Cookies they utilize and, in certain circumstances, obtain the consent of their users to the placement of certain Cookies. Each Cookie used by Travlog falls within one of the following categories:</p>
        <li>
          <Strong>Essential Cookies</Strong>
        </li>
        <p>Essential Cookies (First-Party Cookies) are sometimes called “strictly necessary,” as without them we cannot provide many services that you need. For example, essential Cookies help remember your preferences as you use our Platform and/or Services.</p>
        <li>
          <Strong>Analytics Cookies</Strong>
        </li>
        <p>These Cookies track information about your visits and usage of the Platform and/or Services so that we can make improvements and report our performance — for example, to analyze visitor and user behavior so as to provide more relevant content or suggest certain activities. We might also use analytics Cookies to test new ads, pages, or features to see how users react to them. Google Analytics is the main technology we currently use in this regard. To learn more about Google Analytics and your privacy, visit the “How Google uses data when you use our partners’ sites or apps” page at <a href="www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/.</a> To opt out of being tracked by Google Analytics when using our website, visit <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>.</p>
        <li>
          <Strong>Functionality or preference Cookies</Strong>
        </li>
        <p>During your visit to the Platform, Cookies are used to remember information you have entered or choices you make (such as your username, language, or your region). They also store your preferences when personalizing the Platform to optimize your use of Travlog (for example, your preferred language). These preferences are remembered through the use of persistent Cookies, and the next time you visit the Platform or use our Services, you will not have to set them again.</p>
        <li>
          <Strong>Targeting or Advertising Cookies</Strong>
        </li>
        <p>These Third-Party Cookies are placed by third-party advertising platforms or networks in order to deliver ads and track ad performance or enable advertising networks to deliver ads that may be relevant to you based upon your activities (this is sometimes called “behavioral tracking” or “targeted” advertising) on the Platform. They may subsequently use information about your visit to target you with advertising that you may be interested in on the Platform and other websites. You can opt out of interest-based targeting provided by participating ad servers through the Digital Advertising Alliance.</p>
        <li>
          <Strong>Social Media Cookies:</Strong>
        </li>
        <p>The Platform may incorporate certain third-party social media features, such as the Facebook Like button, and third-party widgets, such as the ‘Share This’ button or interactive mini-programs that run on the Platform. These features may collect your IP address and which page you are visiting on the Platform, as well as set a Cookie to enable the feature to function properly. Your interaction with these features is governed by the privacy policy of the third-party company providing it. Here are some examples of third-party social media features that we use, and their privacy policies:</p>
        <li>
            <ul><a href="https://www.facebook.com/about/privacy">Facebook Privacy Policy</a></ul>
            <ul><a href="https://help.instagram.com/155833707900388">Instagram Privacy Policy</a></ul>
            <ul><a href="https://www.youtube.com/howyoutubeworks/our-commitments/protecting-user-data/">Youtube Privacy Policy</a></ul>
            <ul><a href="https://usds.tiktok.com/?gclid=Cj0KCQiAzoeuBhDqARIsAMdH14Eja3EtkDeittFPACDRXpGx8OJOEAFb3KqSL3xLx980qO2T0VNxIi8aAiRTEALw_wcB">Tik Tok Privacy Policy</a></ul>
            <ul><a href="https://twitter.com/privacy">X (formerly Twitter) Privacy Policy</a></ul>
        </li>
        <br>
        <ol>
          <li><h5>Cookies Subject to Change</h5></li>
        </ol>
        <p>The content of this Cookie Policy is for your general information and use only. You acknowledge that this information may contain inaccuracies or errors and is subject to change, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
        <ol>
          <li><h5>Updating this Cookie Policy</h5></li>
        </ol>
        <p>If there are any material changes to this Cookie Policy, you will be notified by the posting of a prominent notice on our Platform prior to the change becoming effective. We encourage you to periodically review this page for the latest information. Your continued use of the Platform and/or Services constitutes your agreement to be bound by such changes to this Cookie Policy. Your only remedy, if you do not accept the terms of this Cookie Policy, is to discontinue use of and access to the Platform and/or Services.</p>
        <ol>
          <li><h5>Contact Us</h5></li>
        </ol>
        <p>If you have any questions regarding this Cookie Policy or how Travlog uses Cookies, you may contact us by emailing us at <a href="mailto:support@travlogapp.com">support@travlogapp.com</a></p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "TargetedAds",
  };
  </script>
  