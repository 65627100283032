<template>
  <div class="col-lg-12">
    <div class="post-item-1" style="">
      <img
        v-bind:src="blogPost.CoverImage"
        style="max-height: 200px; border-radius: 10px 10px 10px 0"
      />
      <div class="b-post-details" style="padding-bottom: 0">
        <div class="bp-meta">
          <a href="#"><i class="fal fa-clock"></i>{{ blogPost.DateCreated }}</a>
        </div>
        <h3>
          <a v-bind:href="blogPost.BlogID">{{ blogPost.Title }}</a>
        </h3>
        <a class="read-more" style="height: 50px" v-bind:href="blogPost.link"
          >Read More<i class="fal fa-arrow-right"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogItem",
  props: {
    blogPost: {
      Title: String,
      Author: String,
      City: String,
      Country: String,
      Continent: String,
      DateCreated: String,
      Type: String,
      Region: String,
      CoverImage: String,
      BlogID: String,
    },
  },
  methods: {
    convertDate(date) {
      date = new Date(date);
      return date.toDateString();
    },
  },
};
</script>
