<template>
  <div>
    <header-home-one :menu-items="navs" />
    <Post />
    <footer-home-one />
  </div>
</template>

<script>
import Post from "./Post.vue";
import HeaderHomeOne from "../HomeOne/HeaderHomeOne.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import openApp from "../../shared/functions/openapp";

export default {
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  components: { Post, HeaderHomeOne, FooterHomeOne },
  methods: {
    handleWindowFocus() {
      setTimeout(() => {
        this.redirectToHomePage();
      }, 1000); // Redirect after 1 second
      // }
    },
    redirectToHomePage() {
      // Perform the redirection to the home page
      const appStoreUrl =
        "https://apps.apple.com/in/app/apple-store/id375380948";
      const playStoreUrl =
        "https://play.google.com/store/apps/details?id=com.twirsapps.weatherforecast";

      const startTime = Date.now();

      setTimeout(() => {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < 1500) {
          if (navigator.userAgent.match(/(iPhone|iPod|iPad|Mac)/i)) {
            window.location.href = appStoreUrl;
          } else {
            window.location.href = playStoreUrl;
          }
        }
      }, 1000);
      // this.$router.push({ name: "HomeOne" });
    },
  },
  data() {
    return {
      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About Us",
          path: "/about-us",
        },
        {
          name: "Blog",
          path: "/blog",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "FAQs",
          path: "/faqs",
        },
      ],
    };
  },
  mounted() {
    console.log("test");
    window.addEventListener("focus", this.handleWindowFocus);
    if (window.location.href.includes("/postID/")) {
      let res = window.location.href;
      // res.replace("https://", "");
      // console.log(res.replace("https://", ""));
      openApp(`travlog://${res.replace("https://", "")}`);
    } else {
      openApp();
    }
  },
  destroyed() {
    window.removeEventListener("focus", this.handleWindowFocus);
  },
};
</script>
