<template>
  <section class="appie-info-area pt-90 pb-95">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h2 class="appie-title">What is Travlog: The Travel Journal?</h2>
            <p>
              You probably guessed right! Travlog is the #1 travel journal app;
              your ultimate tool for documenting and sharing travel experiences.
              Easily create beautiful travel journals with photos, videos, and
              notes. Share your adventures with friends, connect with fellow
              travelers, and exchange recommendations, saving favorites to your
              wishlist. With flexible privacy options, choose to keep your
              journals private or share them publicly. Join the Travlog
              community today and start capturing every moment of your journey
              with the best travel journal app.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppDescription",
};
</script>

<style>
.appie-info-area {
  padding-top: 90px;
  padding-bottom: 95px;
  background-color: #f2e5e5;
}

.appie-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.appie-section-title p {
  font-size: 1rem;
}
</style>
