<template>
  <div style="height: 100%">
    <header-home-one :menu-items="navs" />
    <Support />
    <footer-home-one />
  </div>
</template>

<script>
import HeaderHomeOne from "../HomeOne/HeaderHomeOne.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import Support from "./Support.vue";

export default {
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderHomeOne,
    Support,
    FooterHomeOne,
  },
  data() {
    return {
      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About Us",
          path: "/about-us",
        },
        {
          name: "Blog",
          path: "/blog",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "FAQs",
          path: "/faqs",
        },
      ],
    };
  },
};
</script>
