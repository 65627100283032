<template>
  <!--====== APPIE FEATURES PART START ======-->
  <section class="appie-features-area pt-100 pb-100" id="features">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <div class="appie-features-tabs-btn">
            <div
              class="nav flex-column nav-pills"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                @click.prevent="selectFeature('scrapbook')"
                class="nav-link"
                :class="[selectedTab === 'scrapbook' ? 'active' : '']"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                ><i class="fas fa-book-open"></i> Scrapbook</a
              >
              <a
                @click.prevent="selectFeature('reminise')"
                class="nav-link"
                :class="[selectedTab === 'reminise' ? 'active' : '']"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                ><i class="fas fa-head-side"></i> Reminise</a
              >
              <a
                @click.prevent="selectFeature('share')"
                class="nav-link"
                :class="[selectedTab === 'share' ? 'active' : '']"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
                ><i class="fas fa-share"></i> Share</a
              >
              <a
                @click.prevent="selectFeature('learn')"
                class="nav-link"
                :class="[selectedTab === 'learn' ? 'active' : '']"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
                ><i class="fas fa-lightbulb"></i> Learn</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="tab-content">
            <div
              :class="[selectedTab === 'scrapbook' ? 'active show' : '']"
              class="tab-pane fade"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center wow animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img src="@/assets/images/feature-1.png" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3 class="title">
                      Keep A <br />
                      Digital Scrapbook
                    </h3>
                    <p>Easily keep track of all your travel experiences</p>
                    <router-link class="main-btn" to="/about-us"
                      >Download Now</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[selectedTab === 'reminise' ? 'active show' : '']"
              class="tab-pane fade"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img src="@/assets/images/feature-2.png" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3 class="title">
                      Remember All <br />
                      Your Trips
                    </h3>
                    <p>Jump back into all your old memories</p>
                    <a class="main-btn" href="#">Download Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[selectedTab === 'learn' ? 'active show' : '']"
              class="tab-pane fade"
              role="tabpanel"
              aria-labelledby="v-pills-messages-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img src="@/assets/images/feature-3.png" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3 class="title">
                      Get Inspiration <br />
                      From Others
                    </h3>
                    <p>Find hidden gems across the world</p>
                    <a class="main-btn" href="#">Download Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[selectedTab === 'share' ? 'active show' : '']"
              class="tab-pane fade"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img src="@/assets/images/feature-4.png" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3 class="title">
                      Share Your Time<br />
                      Away From Home
                    </h3>
                    <p>Build a community to spread and gain inspiration</p>
                    <a class="main-btn" href="#">Download Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="features-shape-1">
            <img src="@/assets/images/shape/worldwide1.png" width="50px" alt="">
        </div>
        <div class="features-shape-2">
            <img src="@/assets/images/shape/worldwide.png" width="100px" alt="">
        </div>
        <div class="features-shape-3">
            <img src="@/assets/images/shape/worldwide.png" width="100px" alt="">
        </div> -->
  </section>

  <!--====== APPIE FEATURES PART ENDS ======-->
</template>

<script>
export default {
  data() {
    return {
      selectedTab: "scrapbook",
      switchPlan: true,
    };
  },
  methods: {
    selectFeature(name) {
      this.selectedTab = name;
    },
  },
};
</script>

<style></style>
