<template>
  <Privacy />
</template>

<script>
import Privacy from "../components/Privacy/index";
export default {
  components: { Privacy },
};
</script>
