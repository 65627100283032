import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeOne from '../views/HomeOne.vue'
import News from '../views/News.vue'
import SingleNews from '../views/SingleNews.vue'
import Contact from '../views/Contact.vue'
import ServiceDetails from '../views/ServiceDetails'
import AboutUs from '../views/About.vue'
import Privacy from '../views/Privacy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import Post from '../views/Post.vue'
import Faq from '../views/Faq.vue'
import AcceptableUse from '../views/AcceptableUse.vue'
import TargetedAds from '../views/TargetedAds.vue'
import Cookies from '../views/Cookies.vue'
import Accessibility from '../views/Accessibility.vue'
import PrivacyRights from '../views/PrivacyRights.vue'
import Support from '../views/Support.vue'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'HomeOne',
    component: HomeOne
  },
  {
    path: '/service-details',
    name: 'ServiceDetails',
    component: ServiceDetails
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/acceptableuse',
    name: 'AcceptableUse',
    component: AcceptableUse
  },
  {
    path: '/targetedads',
    name: 'TargetedAds',
    component: TargetedAds
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies
  },
  {
    path: '/accessibility',
    name: 'Accessibility',
    component: Accessibility
  },
  {
    path: '/privacyrights',
    name: 'PrivacyRights',
    component: PrivacyRights
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/faqs',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/blog',
    name: 'Blog',
    component: News
  },
  {
    path: '/post',
    name: 'Post',
    component: Post
  },
  {
    path: '/postID/:id',
    component: Post
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },

  {
    path: '/blog/:id',
    name: 'SingleBlog',
    component: SingleNews
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '*',
    name: 'Other',
    component: HomeOne
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})

export default router
