<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
          <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
          <div class="layer layer-one"><span class="overlay"></span></div>
          <div class="layer layer-two"><span class="overlay"></span></div>        
          <div class="layer layer-three"><span class="overlay"></span></div>        
      </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== APPIE HEADER PART START ======-->

    <header-home-one @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HEADER PART ENDS ======-->

    <div class="appie-about-top-title-area" style="margin-bottom: 10%">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="appie-about-top-title">
              <h2 class="title">Frequently Asked Questions</h2>
              <br />
              <br />
              <h3>Home Page</h3>
              <h4>How can I find new friends?</h4>
              <p style="color: #0e11338f">
                To discover new friends, simply tap the "Add User" button
                located on the top left of the Home screen. This will direct you
                to a search screen where you can enter any user's name and find
                them.
              </p>
              <br />
              <h4>How can I share a post?</h4>
              <p style="color: #0e11338f">
                Sharing a post is easy! You can either tap the arrow underneath
                the image or use the "three dots" button located on the top
                right of the post.
              </p>
              <br />
              <h4>How can I see where the user recommends going?</h4>
              <p style="color: #0e11338f">
                Look for the "Recs" section underneath the post description. Tap
                the down arrow to reveal all the user's recommendations.
              </p>
              <br />
              <h4>How can I save a recommendation?</h4>
              <p style="color: #0e11338f">
                Simply select the locations you want to save to your Wishlist
                from the "Recs" dropdown and then press the "Send to Wishlist"
                button. This will save them to your profile for later viewing.
              </p>
              <br />
              <h4>How can I view my notifications?</h4>
              <p style="color: #0e11338f">
                Navigate to the bell icon located on the top right of the home
                page. If there's a number displayed, it indicates new
                notifications awaiting your attention.
              </p>
              <br />
              <h4>How do I view the comments on a post?</h4>
              <p style="color: #0e11338f">
                To view comments, tap the comment button located underneath the
                image or the "# Comments" text.
              </p>
              <br />
              <h4>How do I view the Likes on a post?</h4>
              <p style="color: #0e11338f">
                Simply tap on the "# Like" text to navigate to the Like section.
              </p>
              <h4>Can I report another post?</h4>
              <p style="color: #0e11338f">
                Yes, just tap on the “Three Dots” menu icon located at the top
                right of the post you are trying to block and select "Report
                Post". You can also report comments if you find them offensive.
              </p>
              <br />
              <h3>Create</h3>
              <h4>How can I store a memory?</h4>
              <p style="color: #0e11338f">
                Start by creating a "Trip" where you'll input your origin and
                trip dates. Afterward, add destinations to your trip on the "Add
                Destination" page. Once completed, select "Finish" to save your
                post to memories. You can then choose to share it with your
                friends.
              </p>
              <br />
              <h4>
                What is the difference between “Add Destination” and “Share with
                your Friends”?
              </h4>
              <p style="color: #0e11338f">
                Adding a destination saves information to your personal
                memories, while sharing with friends allows you to create a post
                visible to your followers.
              </p>
              <br />
              <h4>Can I keep my memories private?</h4>
              <p style="color: #0e11338f">
                Yes, memories are private by default. You can choose to share
                them with friends after completing the "Add Destination" screen
                or adjust your privacy settings accordingly.
              </p>
              <br />
              <h4>What is “Place of Origin”?</h4>
              <p style="color: #0e11338f">
                This refers to the location the trip started. We like to think
                of this as the airport you left from to begin the trip.
              </p>
              <br />
              <h4>What is “Trip Color”?</h4>
              <p style="color: #0e11338f">
                This refers to the color of the line that will appear on your
                globe for this specific trip.
              </p>
              <br />
              <h4>Can I edit trips?</h4>
              <p style="color: #0e11338f">
                Yes, in the “My Trips” page (found by selecting the suitcase on
                the top right of “Add Destination” when selecting Create on the
                Navigation Bar) there are “Three Dot” Menu buttons where you can
                edit or delete the trip.
              </p>
              <br />
              <h4>
                Can I share with friends if I accidentally selected “No Thanks”?
              </h4>
              <p style="color: #0e11338f">
                Unfortunately, once declined, you cannot share the post with
                friends. You would need to delete the location from memories and
                recreate it.
              </p>
              <br />
              <h4>How many friends can I add?</h4>
              <p style="color: #0e11338f">
                The limit to friends you can add is 5.
              </p>
              <br />
              <h4>How many images can I add?</h4>
              <p style="color: #0e11338f">
                For personal memories, there's no limit. However, when sharing
                with friends, you can add up to 6 images per post.
              </p>
              <br />
              <h4>Can I add a video?</h4>
              <p style="color: #0e11338f">
                We only support adding videos to memories, adding videos to
                posts is not currently possible.
              </p>
              <br />
              <h3>Memories</h3>
              <h4>How can I differentiate between trips I’ve taken?</h4>
              <p style="color: #0e11338f">
                Each trip has its own starting point. If two trips begin from
                the same location, you can edit one of them and change the color
                of the line on the globe, making it easier to distinguish
                between them.
              </p>
              <br />
              <h4>How can I view what’s in my trip?</h4>
              <p style="color: #0e11338f">
                On the globe, simply click on each pin to bring up a bottom
                sheet. From there, you can select the specific destination you
                want to explore further. Additionally, you can navigate to the
                list view, find the date of your trip, and click on it to gain
                more insight into your experiences.
              </p>
              <br />
              <h4>How can I view my journal?</h4>
              <p style="color: #0e11338f">
                When viewing the details of a location, navigate to the
                “Details” tab where you can find specific dates, your journal
                entry (if provided), and all the places you visited during your
                trip.
              </p>
              <br />
              <h4>How can I edit my journal?</h4>
              <p style="color: #0e11338f">
                In the “Details” tab of a location, simply scroll to the bottom
                where you'll find options to delete or edit your journal entry.
              </p>
              <br />
              <h4>Can I filter my globe?</h4>
              <p style="color: #0e11338f">
                Absolutely! We understand that your globe can get cluttered with
                multiple trips. To tidy things up, look for the filter mechanism
                in the top left corner. Select the years you want to display and
                apply the filter for a clearer view.
              </p>
              <br />
              <h4>Can I refresh my globe?</h4>
              <p style="color: #0e11338f">
                Definitely! If you've recently added a new location to your trip
                and it hasn't appeared on the globe yet, head to the filter
                section on the top right. There, you'll find a “Refresh” option
                to update the globe with the most recent data.
              </p>
              <br />
              <h3>Explore</h3>
              <h4>How can I find exciting things to do around the world?</h4>
              <p style="color: #0e11338f">
                Navigate to the “Community” tab under the explore page, where
                you can search for any destination or user that interests you.
                From there, you'll be able to browse posts from that location
                and save their recommendations for future adventures.
              </p>
              <br />
              <h4>How can see all the recommendations I have saved?</h4>
              <p style="color: #0e11338f">
                Head to the “Wishlist” tab under the explore page to view all
                your saved recommendations. If you're looking for a specific
                location, simply use the search bar, and it will populate with
                your saved recommendations as well.
              </p>
              <br />
              <h4>Can I add to a Wishlist outside of saving it from a post?</h4>
              <p style="color: #0e11338f">
                You have full control over your Wishlist. In each location,
                there's an edit button that allows you to manually enter data.
                Additionally, if the location you want isn't present, you can
                use the “Add Wishlist” button to manually add data.
              </p>
              <br />
              <h3>Profile</h3>
              <h4>
                How can I find specific posts when in my profile or someone
                else’s?
              </h4>
              <p style="color: #0e11338f">
                Utilize the search functionality and enter the location you're
                looking for. This will help you quickly locate the desired posts
                within your profile or someone else's.
              </p>
              <br />
              <h4>Can I edit a post?</h4>
              <p style="color: #0e11338f">
                Yes, if you're the owner of a post, just tap on the “Three Dots”
                menu icon located at the top right of the post to bring up the
                post edit screen.
              </p>
              <br />
              <h4>Can I block someone?</h4>
              <p style="color: #0e11338f">
                Yes, just tap on the “Three Dots” menu icon located at the top
                right of the profile you are trying to block and select "Block
                User".
              </p>
              <h4>Can I edit my personal data?</h4>
              <p style="color: #0e11338f">
                Definitely! You have the flexibility to edit most of your data.
                Simply navigate to the “Profile” screen and look for the edit
                button to make the desired changes.
              </p>
              <br />
              <h4>How can I see my followers and who I am following?</h4>
              <p style="color: #0e11338f">
                Access these metrics easily on your profile screen. Simply tap
                on the respective text or counts to navigate to the
                corresponding list.
              </p>
              <br />
              <h4>
                Is there a difference between the memory globe and the profile
                globe?
              </h4>
              <p style="color: #0e11338f">
                Yes, the profile globe showcases your posts, while the memory
                globe displays your private data, providing you with different
                perspectives on your experiences.
              </p>
              <br />
              <h4>Can I filter a profile globe?</h4>
              <p style="color: #0e11338f">
                Absolutely! To maintain clarity and organization, use the filter
                mechanism located in the top left corner of the profile globe.
                Select the desired years to display and apply the filter for a
                more organized view.
              </p>
              <br />
              <h4>Can I refresh a profile globe?</h4>
              <p style="color: #0e11338f">
                Of course! If you've recently added a new post and it's not
                appearing on the globe immediately, head to the filter section
                on the top right. There, you'll find a “Refresh” option to
                update the globe with the most recent data.
              </p>
              <h4>Can I report another user?</h4>
              <p style="color: #0e11338f">
                Yes, just tap on the “Three Dots” menu icon located at the top
                right of the profile you are trying to block and select "Report
                User".
              </p>
              <br />
              <h3>Settings</h3>
              <h4>Can I provide feedback anywhere?</h4>
              <p style="color: #0e11338f">
                Yes, in our application, there is the “Rate Travlog” button
                which allows you to send us feedback. Also, you can email us
                here at feedback@travlogapp.com.
              </p>
              <br />
              <h4>I need technical support, can I receive help?</h4>
              <p style="color: #0e11338f">
                There is a support tab in the settings area. Please use this to
                ask for any help. Furthermore, you can reach out to us at
                support@travlogapp.com.
              </p>
              <br />
              <h3>Sign Up</h3>
              <h4>I didn’t receive my confirmation code by email.</h4>
              <p style="color: #0e11338f">
                Please use the resend feature and a new code will be sent to
                you. If not, double-check you entered the correct email and
                check your spam folder.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->

    <!-- <service-aboutus /> -->

    <!--====== APPIE SERRVICE 2 PART ENDS ======-->

    <!--====== APPIE TEAM PART START ======-->

    <!--====== APPIE TEAM PART ENDS ======-->

    <!--====== APPIE SPONSER PART START ======-->

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE SPONSER PART ENDS ======-->
    <!-- <get-started /> -->

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one class="appie-footer-area appie-footer-about-area" />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import HeaderHomeOne from "../HomeOne/HeaderHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
// import GetStarted from "./GetStarted.vue";
// import ServiceAboutus from "./ServiceAboutus.vue";
export default {
  metaInfo: {
    title: "Frequently Asked Questions",
  },
  components: {
    SidebarHomeOne,
    HeaderHomeOne,
    // ServiceAboutus,
    // GetStarted,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,

      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About Us",
          path: "/about-us",
        },
        {
          name: "Blog",
          path: "/blog",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "FAQs",
          path: "/faqs",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>
