<template>
  <div>
    <!-- <div v-if="showloading">
      <loader-appie />
    </div> -->
    <div>
      <!--====== PRELOADER PART START ======-->

      <!-- <div class="loader-wrap">
        <div class="prelo<div v-if="showloading">
      <loader-appie />
    </div>
    <div v-else>ader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

      <!--====== PRELOADER PART ENDS ======-->

      <!--====== OFFCANVAS MENU PART START ======-->

      <sidebar-home-one
        :sidebar="sidebar"
        @toggleSidebar="toggleSidebar"
        :menuItems="navs"
      />

      <!--====== OFFCANVAS MENU PART ENDS ======-->

      <!--====== PART START ======-->

      <header-home-one @toggleSidebar="toggleSidebar" :menuItems="navs" />

      <!--====== PART ENDS ======-->

      <!--====== APPIE HERO PART START ======-->

      <hero-home-one
        welcome_message="Welcome To Travlog: The Travel Journal"
        slogan="Map Your Memories"
        description="Join our global community where we share our travel stories, preserve memories, and encourage others to explore the world in the best new travel journal"
        first_button_content="Download for iOS"
        first_button_icon="fab fa-apple"
        second_button_content="Download for Android"
        second_button_icon="fab fa-google-play"
      />

      <!--====== APPIE HERO PART ENDS ======-->
      <!--====== APPIE SERVICES PART START ======-->
      <!-- <services-home-one
        :grid_items="itemsGrid"
        section_heading="Designed with"
        more_heading="The applicant in mind."
        description="The full monty spiffing good time no biggie cack grub fantastic."
      /> -->
      
      <!--====== APPIE SERVICES PART ENDS ======-->
      <AppDescription />

      <!--====== APPIE FEATURES PART START ======-->
      <features-home-one />

      <!--====== APPIE FEATURES PART ENDS ======-->

      <!--====== APPIE TRAFFIC PART START ======-->

      <!-- <traffic-home-one
        :traffic_thumbnail="traffic_thumb"
        small_title="Traffic"
        big_title="Stage reporting with  50k queries."
        description="He nicked it tickety boo harry the cras bargy chap mush spiffing spend a penny the full monty burke butty."
        :features="features"
      /> -->

      <!--====== APPIE TRAFFIC PART ENDS ======-->

      <!--====== APPIE TESTIMONIAL PART START ======-->

      <testimonial-home-one :sliderContents="sliderContents" />

      <!--====== APPIE TESTIMONIAL PART ENDS ======-->

      <!--====== APPIE TEAM PART START ======-->

      <!-- <team-home-one
        title="Meet our Team Members"
        description="Different layouts and styles for team sections."
        :members="members"
      /> -->

      <!--====== APPIE TEAM PART ENDS ======-->

      <!--====== APPIE PRICING PART START ======-->

      <!-- <priceing-home-one
        title="Choose your plan"
        description="Different layouts and styles for team sections."
      /> -->

      <!--====== APPIE PRICING PART ENDS ======-->

      <!--====== APPIE FAQ PART START ======-->

      <!-- <faq-home-one
        title="Frequently asked questions"
        description="Different layouts and styles for team sections."
      /> -->

      <!--====== APPIE FAQ PART ENDS ======-->

      <!--====== APPIE BLOG PART START ======-->

      <!-- <blog-home-one
        title="Latest Articles"
        description="Discover the marvels of the world through the eyes of our seasoned globetrotters"
        :blogs="blogs"
      /> -->

      <!--====== APPIE BLOG PART ENDS ======-->

      <!--====== APPIE PROJECT PART START ======-->

      <project-home-one />
    </div>

    <!--====== APPIE PROJECT PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>
<script>
import blogOne from "@/assets/images/blog-1.jpg";
import blogTwo from "@/assets/images/blog-2.jpg";
import blogThree from "@/assets/images/blog-3.jpg";
import JordanBennett from "@/assets/images/JordanBennett.png";
import AnthonyRanieri from "@/assets/images/AnthonyRanieri.png";
import NicoleClarmin from "@/assets/images/NicoleClarmin.png";
import testmonialAvater from "@/assets/images/testimonial-user.png";
import Harper from "@/assets/images/Harper.png";
import traffic_thumb from "@/assets/images/traffic-thumb.png";
import icon_one from "@/assets/images/icon/1.png";
import icon_two from "@/assets/images/icon/2.png";
import icon_three from "@/assets/images/icon/3.png";
import icon_four from "@/assets/images/icon/4.png";
import HeaderHomeOne from "./HeaderHomeOne.vue";
import SidebarHomeOne from "./SidebarHomeOne.vue";
import HeroHomeOne from "./HeroHomeOne.vue";
import AppDescription from "./AppDescription.vue";
// import ServicesHomeOne from "./ServicesHomeOne.vue";
import FeaturesHomeOne from "./FeaturesHomeOne.vue";
// import TrafficHomeOne from "./TrafficHomeOne.vue";
import TestimonialHomeOne from "./TestimonialHomeOne.vue";
// import TeamHomeOne from "./TeamHomeOne.vue";
// import PriceingHomeOne from "./PriceingHomeOne.vue";
// import FaqHomeOne from "./FaqHomeOne.vue";
// import BlogHomeOne from "./BlogHomeOne.vue";
import ProjectHomeOne from "./ProjectHomeOne.vue";
import FooterHomeOne from "./FooterHomeOne.vue";
// import LoaderAppie from "../LoaderAppie.vue";
// import MailChimp from "../MailChimp.vue";
export default {
  name: "HomeOne",
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderHomeOne,
    SidebarHomeOne,
    HeroHomeOne,
    AppDescription,
    // ServicesHomeOne,
    FeaturesHomeOne,
    // TrafficHomeOne,
    TestimonialHomeOne,
    // TeamHomeOne,
    // PriceingHomeOne,
    // FaqHomeOne,
    // BlogHomeOne,
    ProjectHomeOne,
    FooterHomeOne,
    // LoaderAppie,
    // MailChimp
  },
  data() {
    return {
      traffic_thumb: traffic_thumb,
      testmonialAvater: testmonialAvater,
      sidebar: false,
      showloading: true,
      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About Us",
          path: "/about-us",
        },
        {
          name: "Blog",
          path: "/blog",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "FAQs",
          path: "/faqs",
        },
      ],
      itemsGrid: [
        {
          count: 1,
          icon: icon_one,
          title: "Searching",
          content: "Mucker plastered bugger all mate morish are.",
          customClass: "",
        },
        {
          count: 2,
          icon: icon_two,
          title: "Designing",
          content: "Mucker plastered bugger all mate morish are.",
          customClass: "item-2",
        },
        {
          count: 3,
          icon: icon_three,
          title: "Building",
          content: "Mucker plastered bugger all mate morish are.",
          customClass: "item-3",
        },
        {
          count: 4,
          icon: icon_four,
          title: "Suporting",
          content: "Mucker plastered bugger all mate morish are.",
          customClass: "item-4",
        },
      ],
      features: [
        {
          title: "Carefully designed",
          bg: "", // create class for style
          description: "Mucker plastered bugger all mate morish are.",
        },
        {
          title: "Choose a App",
          bg: "item-2", // create class for style
          description: "Mucker plastered bugger all mate morish are.",
        },
        {
          title: "Seamless Sync",
          bg: "item-3", // create class for style
          description: "Mucker plastered bugger all mate morish are.",
        },
        {
          title: "User Interactive",
          bg: "item-4", // create class for style
          description: "Mucker plastered bugger all mate morish are.",
        },
      ],
      sliderContents: [
        {
          title: "Jordan Bennett",
          date: "June 5, 2024",
          description:
            "Travlog is the best travel journal app I've ever used! It's incredibly easy to document my trips with photos, videos, and notes. Sharing my adventures with friends and connecting with other travelers has made my journeys even more enjoyable.",
          ratings: 5,
          avatar: JordanBennett,
        },
        {
          title: "Anthony Ranieri",
          date: "July 17, 2024",
          description:
            "The Travlog travel journal app has completely transformed how I document my travels. The flexible privacy options give me the freedom to share some journals publicly while keeping others private. It's a must-have app for any traveler!!",
          ratings: 5,
          avatar: AnthonyRanieri,
        },
        {
          title: "Harper Davis",
          date: "June 20, 2024",
          description:
            "Using Travlog as my travel journal has been a game-changer. I can effortlessly capture every detail of my trips, from stunning photos to memorable experiences. It's the perfect travel journal for anyone who loves to explore and document their adventures.",
          ratings: 5,
          avatar: Harper,
        },
        {
          title: "Nicole Clarmmin",
          date: "May 29, 2024",
          description:
            "Travlog is the ultimate travel scrapbook app! It allows me to create beautiful, detailed scrapbooks of all my journeys. With the cool globe feature, I can relive every moment and share my travel stories with friends and family.",
          ratings: 5,
          avatar: NicoleClarmin,
        },
      ],
      // memberOne: memberOne,
      // memberTwo: memberTwo,
      // memberThree: memberThree,
      // memberFour: memberFour,
      // members: [
      //   {
      //     name: "Benjamin Evalent",
      //     position: "CEO-Founder",
      //     img: memberTwo,
      //     socials: [
      //       {
      //         name: "facebok",
      //         url: "https://www.facebook.com",
      //         icon: "fab fa-facebook-f", //user font awesome class
      //       },
      //       {
      //         name: "twitter",
      //         url: "https://www.twitter.com",
      //         icon: "fab fa-twitter", //user font awesome class
      //       },
      //       {
      //         name: "pinterest",
      //         url: "https://www.pinterest.com/",
      //         icon: "fab fa-pinterest-p", //user font awesome class
      //       },
      //     ],
      //   },
      //   {
      //     name: "Benjamin Evalent",
      //     position: "CEO-Founder",
      //     img: memberOne,
      //     socials: [
      //       {
      //         name: "facebok",
      //         url: "https://www.facebook.com",
      //         icon: "fab fa-facebook-f", //user font awesome class
      //       },
      //       {
      //         name: "twitter",
      //         url: "https://www.twitter.com",
      //         icon: "fab fa-twitter", //user font awesome class
      //       },
      //       {
      //         name: "pinterest",
      //         url: "https://www.pinterest.com/",
      //         icon: "fab fa-pinterest-p", //user font awesome class
      //       },
      //     ],
      //   },
      //   {
      //     name: "Benjamin Evalent",
      //     position: "CEO-Founder",
      //     img: memberThree,
      //     socials: [
      //       {
      //         name: "facebok",
      //         url: "https://www.facebook.com",
      //         icon: "fab fa-facebook-f", //user font awesome class
      //       },
      //       {
      //         name: "pinterest",
      //         url: "https://www.pinterest.com/",
      //         icon: "fab fa-pinterest-p", //user font awesome class
      //       },
      //     ],
      //   },
      //   {
      //     name: "Benjamin Evalent",
      //     position: "CEO-Founder",
      //     img: memberFour,
      //     socials: [
      //       {
      //         name: "facebok",
      //         url: "https://www.facebook.com",
      //         icon: "fab fa-facebook-f", //user font awesome class
      //       },
      //       {
      //         name: "twitter",
      //         url: "https://www.twitter.com",
      //         icon: "fab fa-twitter", //user font awesome class
      //       },
      //       {
      //         name: "pinterest",
      //         url: "https://www.pinterest.com/",
      //         icon: "fab fa-pinterest-p", //user font awesome class
      //       },
      //     ],
      //   },
      // ],
      blogOne: blogOne,
      blogTwo: blogTwo,
      blogThree: blogThree,
      blogs: [
        {
          img: blogOne,
          date: "December 29, 2022",
          category: "Things To Do",
          title: "Lisbon, Portugal",
        },
        {
          img: blogTwo,
          date: "March 4, 2023",
          category: "Things To Do",
          title: "Florence, Italy",
        },
        {
          img: blogThree,
          date: "August 15, 2023",
          category: "Things To Do",
          title: "Galway, Ireland",
        },
      ],
    };
  },
  created() {
    if (!this.rtl) {
      this.preLoading();
    } else {
      this.showloading = false;
    }
    document.addEventListener("scroll", this.topToBottom);
  },

  methods: {
    preLoading() {
      setTimeout(() => {
        this.showloading = false;
      }, 2000);
    },
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>
