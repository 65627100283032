<template>
  <div v-if="showloading">
    <loader-appie />
  </div>
  <div v-else>
    <!--====== PRELOADER PART START ======-->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-news @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <div v-if="showloadingReload">
      <loader-appie />
    </div>

    <title-area />

    <!--====== APPIE PAGE TITLE PART ENDS ======-->

    <!-- Blog Start -->
    <section class="blogpage-section">
      <div class="container" style="max-width: 95%">
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-lg-7">
            <all-blogs :posts="this.blogPosts" />
            <div class="row">
              <div class="bisylms-pagination">
                <div style="display: inline" v-if="current > 1">
                  <a class="prev" href="#" @click="getPage(current - 1, query)">
                    <i class="fal fa-arrow-left"></i>previous
                  </a>
                </div>

                <span class="current">{{ current }}</span>
                <div style="display: inline" v-if="current !== total_pages">
                  <a href="#" @click="getPage(current + 1, query)">{{
                    this.current + 1
                  }}</a>

                  <a class="next" href="#" @click="getPage(current + 1, query)"
                    >next<i class="fal fa-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3">
            <blog-sidebar
              @search="search"
              @filterAuthor="filterAuthor"
              @filterContinent="filterContinent"
              @filterTag="filterTag"
              :authors="authors"
              :continents="continents"
              :tags="tags"
            />
          </div>
          <div class="col-lg-1"></div>
        </div>
      </div>
    </section>
    <!-- Blog End -->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import HeaderNews from "../News/HeaderNews.vue";
import TitleArea from "../News/TitleArea.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import AllBlogs from "../Blogs/AllBlogs.vue";
import LoaderAppie from "../LoaderAppie.vue";

import BlogSidebar from "../BlogSidebar.vue";
import axios from "axios";
export default {
  components: {
    SidebarHomeOne,
    HeaderNews,
    TitleArea,
    FooterHomeOne,
    AllBlogs,
    LoaderAppie,
    BlogSidebar,
  },
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sidebar: false,
      blogPosts: [],
      current: 1,
      tags: null,
      continents: null,
      authors: null,
      searchAuthor: [],
      searchContinent: [],
      searchTag: [],
      total_pages: Number,
      query: "",
      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About Us",
          path: "/about-us",
        },
        {
          name: "Blog",
          path: "/blog",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "FAQs",
          path: "/faqs",
        },
      ],
      showloading: true,
      showloadingReload: false,
    };
  },

  methods: {
    preLoading() {
      setTimeout(() => {
        this.showloading = false;
        this.showloadingReload = false;
      }, 1000);
    },
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    nextPage(pageNumber) {
      this.getBlogs(pageNumber);
    },
    async getPage(pageNumber = 1, query = "") {
      this.showloadingReload = true;
      await this.getBlogs(pageNumber, query);
      this.preLoading();
    },
    getFilter() {
      const url = "https://api.web.travlogapp.com/Filter";

      return axios
        .get(url)
        .then((res) => {
          this.authors = res.data["Author"];
          this.continents = res.data["Continent"];
          this.tags = res.data["PopularTags"];
        })
        .catch((err) => console.log(err));
    },
    getBlogs(
      pageNumber = 1,
      query = "",
      continent = "",
      author = "",
      tags = ""
    ) {
      axios.defaults.headers.common["Cache-Control"] = "max-value=100";

      const url = "https://api.web.travlogapp.com/blog";

      let params;

      // if (query === "") {
      //   params = {
      //     page_number: pageNumber,
      //     continent: `[${continent}]`,
      //     author: `[${author}]`,
      //     tags: `[${tags}]`,
      //   };
      // } else {
      params = {
        page_number: pageNumber,
        query: query,
        continent: `[${continent}]`,
        author: `[${author}]`,
        tags: `[${tags}]`,
      };
      // }

      return axios
        .get(
          url,
          {
            params: params,
          },
          {
            headers: {
              "Cache-Control": "max-age=100",
            },
          }
        )
        .then((res) => {
          this.blogPosts = res.data["Blogs"];
          this.current = res.data["current_page"];
          this.total_pages = res.data["total_pages"];
        })
        .catch((err) => console.log(err));
    },
    search(query) {
      this.query = query;
      this.getBlogs(
        1,
        this.query,
        this.searchContinent,
        this.searchAuthor,
        this.searchTag
      );
    },
    filterAuthor(author) {
      if (!this.searchAuthor.includes(`"${author}"`)) {
        this.searchAuthor.push(`"${author}"`);
      } else {
        this.searchAuthor.pop(
          this.searchAuthor.find((val) => val === `"${author}"`)
        );
      }
      this.getBlogs(
        1,
        this.query,
        this.searchContinent,
        this.searchAuthor,
        this.searchTag
      );
    },
    filterContinent(continent) {
      if (!this.searchContinent.includes(`"${continent}"`)) {
        this.searchContinent.push(`"${continent}"`);
      } else {
        this.searchContinent.pop(
          this.searchContinent.find((val) => val === `"${continent}"`)
        );
      }
      this.getBlogs(
        1,
        this.query,
        this.searchContinent,
        this.searchAuthor,
        this.searchTag
      );
    },
    filterTag(tag) {
      if (!this.searchTag.includes(`"${tag}"`)) {
        this.searchTag.push(`"${tag}"`);
      } else {
        this.searchTag.pop(this.searchTag.find((val) => val === `"${tag}"`));
      }
      this.getBlogs(
        1,
        this.query,
        this.searchContinent,
        this.searchAuthor,
        this.searchTag
      );
    },
  },
  async created() {
    if (!this.rtl) {
      await this.getBlogs(this.current);
      await this.getFilter();
      this.preLoading();
    } else {
      this.showloading = false;
    }
    document.addEventListener("scroll", this.topToBottom);
  },
};
</script>

<style></style>
